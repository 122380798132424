export function checkConditionalRules(elm, elmIndex, valIndex, components, oldShow, depth) {

  // Prevent infinite recursion
  if (depth > 199) console.log('Infinite loop on question: ', elmIndex + 1)
  if (depth && depth > 200) return oldShow
  if (!depth) depth = 0
  depth = depth + 1

  if (!elm) elm = {}
  if (!elm.ConditionalRules) elm.ConditionalRules = []
  elm.ConditionalRules.forEach((rule, ruleIndex) => {
    elm.ConditionalRules[ruleIndex].Valid = false
    const componentIndex = (rule.ComponentID ? Object.values(components || {}).findIndex(e => e.ComponentID === rule.ComponentID) : rule.Component)
    if (componentIndex < 0 || componentIndex === elmIndex) {
      elm.ConditionalRules[ruleIndex].Valid = true
    } else {
      let value = components[componentIndex]?.Value ? components[componentIndex]?.Value?.[valIndex] || [''] : ['']
      try {
        const conditionShowed = checkConditionalRules(components[componentIndex], componentIndex, valIndex, components, [], depth).every(e => e) // Make sure all parent conditionals are also shown
        if (typeof value === 'object') value = Object.values(value).map(e => `${e}`) // If it is an object, map it to an array of strings
        if (!Array.isArray(value)) value = [value]
        if (typeof rule.Value === 'string') rule.Value = [rule.Value]
        if (conditionShowed && rule.ValueType === 'any' && value.some(e => !!e)) elm.ConditionalRules[ruleIndex].Valid = true
        else if (rule.ValueType === 'blank' && (!conditionShowed || value.every(e => !e))) elm.ConditionalRules[ruleIndex].Valid = true
        else if (conditionShowed && (!rule.ValueType || rule.ValueType === 'equal') && value.some(v => rule.Value.some(r => r.trim() === (v || '').trim()))) elm.ConditionalRules[ruleIndex].Valid = true
      } catch (e) {
        console.error(e)
        console.log({ value })
      }
    }
  })
  const show = elm.ConditionalRules.every(rule => rule.Valid)
  const newShow = [...oldShow]
  newShow[elmIndex] = show
  return newShow
}

export function checkRule(rule, value) {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  // eslint-disable-next-line no-useless-escape
  const colorRegex = /#([a-f0-9]{8}|[a-f0-9]{6}|[a-f0-9]{4}|[a-f0-9]{3})\b/gi
  // eslint-disable-next-line no-useless-escape
  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  if (rule === 'Any' || rule === 'Password' || rule === 'Date' || rule === 'Time') return true
  else if (rule === 'Number' && !isNaN(value)) return true
  else if (rule === 'Phone Number' && phoneRegex.test(value ? value.replace(/\D/g, '') : '')) return true
  else if (rule === 'Email' && emailRegex.test(value)) return true
  else if (rule === 'Color' && colorRegex.test(value)) return true
  return false
}

export function validationRuleFormValues() {
  return [
    { label: 'Any Text', value: 'Any' },
    { label: 'Number', value: 'Number' },
    { label: 'Email', value: 'Email' },
    { label: 'Phone Number', value: 'Phone Number' },
    { label: 'Password', value: 'Password' },
    { label: 'Date', value: 'Date' },
    { label: 'Time', value: 'Time' },
    { label: 'Color Hex', value: 'Color' },
  ]
}

export function validationRuleRangeValues() {
  return [
    { label: 'Any Text', value: 'Any' },
    { label: 'Number', value: 'Number' },
    { label: 'Date', value: 'Date' },
    { label: 'Time', value: 'Time' },
  ]
}

export function isUserInputType(elm) {
  return (!['image', 'iFrame', 'link', 'textBlock', 'lineBreak', 'templatePDF'].includes(elm.Type))
}

export function checkCircularDep(sections, index, taskID, depId) {
  // Code from https://github.com/floatdrop/dag
  const edges = {}
  function find(verticle, start) {
    if (!edges[start]) return false
    for (let i = 0; i < edges[start].length; i++) {
      const parent = edges[start][i]
      if (parent === verticle || find(verticle, parent)) return true
    }
    return false
  }
  function add(from, to) {
    if (!edges[to]) edges[to] = []
    edges[to].push(from)
    return !find(from, from)
  }
  add(taskID, depId)
  return Object.values(sections || {}).every(section =>
    Object.values(section[index] || {}).every(task => {
      if (!task.Dependency) return true
      return add(task.TaskID, task.Dependency.ID)
    })
  )
}