/* global process */
import { useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Menu, MenuItem, IconButton, Tabs, Tab, Grid, Card, CardContent, Button, CardActions, Tooltip } from '@material-ui/core'
import { MoreVert, Menu as MenuIcon, Share } from '@material-ui/icons'
import { useSession } from '../session/session'
import { useClient } from '../session/clientSession'
import SaveIndicator from './saveIndicator'
import Skeleton from '@material-ui/lab/Skeleton'
import { setStyle } from '../../utils/utils'
import CardTitle from '../card/title'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import PropTypes from 'prop-types'
import ClientShare from 'components/client/clientShare'
import LogRocket from 'logrocket'
import { localize } from 'components/localization/strings'

const logoHeight = '40px'

const useStyles = makeStyles((theme) => ({
  navText: {
    color: 'var(--top-bar-text)'
  },
  appBar: {
    backgroundColor: 'var(--bar)'
  },
  appBarPreview: {
    '& .MuiToolbar-root': {
      display: 'block'
    }
  },
  logo: {
    height: logoHeight,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    margin: '10px',
    flexGrow: 1,
  },
  spacer: {
    width: '275px'
  },
  card: {
    marginTop: '50px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '275px',
      marginTop: '100px',
    },
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  tab: {
    maxWidth: '100000px'
  }
}))

export default function TopAppBar({ preview }) {
  const session = useSession()
  const clientSession = useClient()
  const classes = useStyles()
  const [openShare, setOpenShare] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [getEmail, setGetEmail] = useState(<></>)
  const [currentTab, setCurrentTab] = useState(Router.pathname.split('/').pop() === 'team' ? 2 : 0)
  const router = useRouter()

  const orgData = preview || session?.org?.data || null
  setStyle(preview || orgData, !!preview, session.external)

  const newOnboarding = Router.pathname.split('/').pop() === 'new'

  useEffect(() => {
    if (!orgData) return
    const newUser = JSON.parse(JSON.stringify(session.user || {}))

    function addClientToClientTeam(email) {
      if (newOnboarding) return
      const ClientTeam = clientSession?.client?.ClientTeam || {}
      const Team = clientSession?.client?.TeamArray || []
      if (!ClientTeam[email] && Team.indexOf(email) < 0) {
        const share = { ClientTeam: {}, Metadata: { Variables: {} } }
        share.Metadata.Variables['Client_Emails'] = [... new Set([...Object.keys(clientSession.client.ClientTeam || {}), email])].filter(e => e != 'public').join(', ')
        share.ClientTeam[email] = { SkipInvite: !router?.query?.invite }
        clientSession.saveClient(share)
      }
    }

    function onSubmit(values) {
      if (values.email && values.email.indexOf('@') > 0) {
        newUser.email = values.email
        localStorage.setItem('userEmail', values.email)
        session.setUser(newUser)
        addClientToClientTeam(values.email)
      }
    }
    setGetEmail(<></>)
    if (!session?.user?.email && (clientSession?.client?.ClientTeam?.public || newOnboarding)) {
      newUser.public = true
      if (router?.query?.email) {
        if (!localStorage.getItem('userEmail')) localStorage.setItem('userEmail', router.query.email)
      }
      if (localStorage.getItem('userEmail')) {
        newUser.email = localStorage.getItem('userEmail')
        if (typeof window != 'undefined' && window?.FS?.identify && typeof window.FS.identify === 'function' && !process.env.NODE_ENV === 'development') {

          try {
            LogRocket.init('nozf6g/simplemnt-prod')
            LogRocket.identify(newUser.email, {
              name: '',
              email: newUser.email
            })

            window.FS.identify(newUser.email, {
              displayName: '',
              email: newUser.email,
            })
          } catch (e) {
            console.error(e)
          }

        }
        addClientToClientTeam(newUser.email)
        session.setUser(newUser)
      } else {
        setGetEmail(<Grid container spacing={1}>
          <Grid item xs={12}><div style={{ height: '50px' }}></div></Grid>
          <Grid item sm={2} xs={false} />
          <Grid item sm={8} xs={12}>
            <Card className={classes.card}>
              <Form
                onSubmit={onSubmit}
                initialValues={{ email: '' }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <CardContent style={{ textAlign: 'center' }}>
                      <CardTitle title={`Welcome to ${orgData?.Name ?? ''}`} subtitle='Please enter your email to continue' />
                      <TextField label="Your Email" name="email" required={true} />
                    </CardContent>
                    <CardActions>
                      <Button type="submit" >Continue</Button>
                    </CardActions>
                  </form>
                )}
              />
            </Card>
          </Grid>
        </Grid >)
      }
    } else if (session?.user?.email && clientSession?.client?.ClientTeam?.public) {
      addClientToClientTeam(session.user.email)
    }
  }, [session.user, orgData, clientSession?.client?.ClientTeam])

  function handleDrawerToggle() {
    session.setMobileSidebarOpen(!session.mobileSidebarOpen)
  }

  const isMenuOpen = Boolean(anchorEl)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const timeline = () => {
    setCurrentTab(0)
    if (!preview) Router.push('/onboarding/[clientid]', `/onboarding/${clientSession.clientRef.id}`)
  }

  const support = () => { if (orgData?.Support?.Link) window.open(orgData?.Support?.Link, '_blank') }

  const team = () => {
    setCurrentTab(2)
    if (!preview) Router.push('/onboarding/[clientid]/team', `/onboarding/${clientSession.clientRef.id}/team`)
  }

  const menuId = 'account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem className={classes.sectionMobile} onClick={() => {
        handleMenuClose()
        timeline()
      }}>Home</MenuItem>

      <MenuItem className={classes.sectionMobile} onClick={() => {
        handleMenuClose()
        support()
      }}>Support</MenuItem>

      <MenuItem className={classes.sectionMobile} onClick={() => {
        handleMenuClose()
        team()
      }}>{`${localize('Contact', clientSession?.client?.Language)} ${orgData?.Name ?? ''}`}</MenuItem>

      <MenuItem onClick={() => {
        handleMenuClose()
        session.signout()
      }}>Sign Out</MenuItem>

    </Menu>
  )

  let name = 'Your Onboarding'
  let clientData
  if (clientSession.client) {
    clientData = clientSession.client
    name = clientData.ClientDisplayName ? clientData.ClientDisplayName : `${clientData.Name}'s Onboarding`
  } else if (preview) {
    clientData = true
  }
  return (
    <div className={classes.grow}>
      <AppBar position={!session.external ? 'static' : 'fixed'} className={`${preview ? classes.appBarPreview : ''} ${classes.appBar}`} elevation={2}>
        <Toolbar>
          {session.user?.email && <div className={classes.sectionMobile}>
            <IconButton
              className={classes.navText}
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            > <MenuIcon />
            </IconButton>
          </div>}
          <div className={classes.sectionDesktop}>
            <div className={classes.spacer} />
            {clientData ?
              <Tabs value={currentTab} className={classes.navText} TabIndicatorProps={{ style: { backgroundColor: 'var(--nav-highlight)' } }} >
                <Tab label={name} onClick={timeline} className={classes.tab} />
                {orgData?.Support?.Link && <Tab label={orgData?.Support?.Name ?? localize('Support', clientSession?.client?.Language)} onClick={support} className={classes.tab} />}
                <Tab label={`${localize('Contact', clientSession?.client?.Language)} ${orgData?.Name ?? ''}`} onClick={team} className={classes.tab} />
              </Tabs>
              : <Skeleton variant='rect' height='40' width='1000px' animation="wave" />}
          </div>
          <div className={`${classes.sectionMobile} ${classes.grow}`}>
            {orgData?.Logo ? <div className={classes.logo} style={{ backgroundImage: `url(${session.org.data.Logo})` }}></div> : <Skeleton height={logoHeight} />}
          </div>
          <div className={classes.grow} />
          <SaveIndicator />
          {session.external &&
            <Tooltip title='Share This Page'><IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={setOpenShare}
              className={classes.navText}
            >
              <Share />
            </IconButton></Tooltip>}
          {session?.user?.email && <div>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              className={classes.navText}
            >
              <MoreVert />
            </IconButton>
          </div>}
        </Toolbar>
      </AppBar>
      {renderMenu}
      {getEmail}
      <ClientShare openShare={openShare} setOpenShare={setOpenShare} />
    </div>
  )
}

TopAppBar.propTypes = {
  preview: PropTypes.object
}
