import { Dialog, Card, CardContent, IconButton, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import CardTitle from 'components/card/title'
import { useClient } from 'components/session/clientSession'
import { Lock } from '@material-ui/icons'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Checkboxes } from 'mui-rff'
import { OnChange } from 'react-final-form-listeners'
import { useSession } from 'components/session/session'

export default function ClientPermissions({ email }) {
  const session = useSession()
  const clientSession = useClient()
  const [open, setOpen] = useState(false)
  if (!clientSession.client?.ClientTeam?.[email]?.NoView) clientSession.client.ClientTeam[email].NoView = {}
  if (!clientSession.client?.ClientTeam?.[email]?.NoEdit) clientSession.client.ClientTeam[email].NoEdit = {}
  return <>
    <IconButton size='small' onClick={() => setOpen(true)}>
      <Lock fontSize='small' />
    </IconButton>
    {open && <Dialog open={open} onClose={() => setOpen(false)} maxWidth='lg'>
      <Card>
        <CardContent>
          <CardTitle title='Permissions' subtitle={email === 'public' ? 'Link Share' : email} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Task</TableCell><TableCell>View</TableCell><TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(clientSession.client?.Metadata?.Sections ?? {}).map((section, sectionIndex) =>
                Object.values(section?.Data ?? {}).map((task, taskIndex) => {
                  const allowEdit = task.TaskType != 'Internal Process'
                  const initialValues = {
                    View: task.TaskType != 'Internal Process',
                    Edit: task.TaskType != 'Internal Process'
                  }

                  const viewOverride = clientSession.client?.ClientTeam?.[email]?.NoView?.[task.Ref]
                  const editOverride = clientSession.client?.ClientTeam?.[email]?.NoEdit?.[task.Ref]
                  if (typeof viewOverride != 'undefined') initialValues.View = !viewOverride
                  if (typeof editOverride != 'undefined') initialValues.Edit = !editOverride

                  function update(type, val) {
                    clientSession.client.ClientTeam[email][type][task.Ref] = !val
                    clientSession.saveClient({ ClientTeam: clientSession.client.ClientTeam })
                    window.analytics.track('hub permissions changed', {
                      event_id: clientSession.clientRef.id,
                      org_id: session.org.ref.id
                    })
                  }
                  return (
                    <TableRow key={`${sectionIndex}-${taskIndex}`}>
                      <TableCell>{task.Name}</TableCell>
                      <Form
                        onSubmit={() => { }}
                        initialValues={initialValues}
                        render={({ values }) => (<>
                          <TableCell><Checkboxes name='View' data={[{ label: '' }]} disabled={values.Edit} /></TableCell>
                          <OnChange name='View'>{val => update('NoView', val)}</OnChange>
                          <TableCell>{allowEdit && <Checkboxes name='Edit' data={[{ label: '' }]} disabled={!values.View} />}</TableCell>
                          <OnChange name='Edit'>{val => update('NoEdit', val)}</OnChange>
                        </>)} //debug: <TableCell>{JSON.stringify(values, 0, 2)}</TableCell>
                      />
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </Table>

        </CardContent>
      </Card>
    </Dialog>}
  </>
}

ClientPermissions.propTypes = {
  email: PropTypes.string.isRequired
}