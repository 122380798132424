/* eslint-disable no-useless-escape */
export function replaceVariables(elm, variables) {
  function compile(elemnt, variables) {
    for (const variable in variables) {
      const rx = new RegExp(`{{[ ]*${variable}[ ]*}}`, 'ig')
      elemnt = elemnt.replace(rx, variables[variable])
    }
    return elemnt
  }
  if (Object.entries(variables).length === 0) return elm //shortcut if no vars are defined
  function deepReplace(elemnt, depth) {
    if (depth > 10) return
    if (!elemnt) {
      return elemnt
    } else if (elemnt.toDate) {
      return elemnt
    } else if (elemnt instanceof Array) {
      const out = []
      for (const val of elemnt) {
        out.push(deepReplace(val, depth + 1))
      }
      return out
    } else if (typeof elemnt === 'object') {
      const out = {}
      for (const [key, val] of Object.entries(elemnt)) {
        out[key] = deepReplace(val, depth + 1)
      }
      return out
    } else if (typeof elemnt === 'string') {
      return compile(elemnt, variables)
    } else {
      return elemnt
    }
  }
  const rendered = deepReplace(elm, 0)
  return rendered
}

export const defaultVars = [
  'Client_Name',
  'Client_Emails',
  'Internal_Emails',
  'StartDate',
  'TargetGoLive',
  'Client_ID',
  'Primary_Scheduling_Link',
  'Primary_Agent_First_Name',
  'Primary_Agent_Last_Name',
  'Primary_Agent_Email',
]

export async function findVariablesInObject(element, clientRef) {
  const vars = []
  async function deepFind(element, depth) {
    if (depth > 10) return
    if (!element) {
      return
    } else if (element.toDate) {
      return
    } if (typeof element === 'string') {
      if (element.match(/{{\s*[\w\.]+\s*}}/g))
        vars.push(...element.match(/{{\s*[\w\.]+\s*}}/g).map(x => x.match(/[\w\.]+/)[0]))
    } else if (element instanceof Array) {
      for (const val of element) {
        await deepFind(val, depth + 1)
      }
    } else if (typeof element === 'object') {
      for (const [key, val] of Object.entries(element)) {
        if (key === 'Ref') {
          await deepFind((await clientRef.collection('Templates').doc(val).get()).data(), 0)
        } else {
          await deepFind(val, depth + 1)
        }
      }
    }
  }
  await deepFind(element, 0)
  return [... new Set(vars)]
}

const varRegex = /{{\s*@[\w\.]+\s*}}/g

export function taskValueVariables(task) {
  if (!task.Components) return {}
  const vars = {}

  function deepFind(element, depth) {
    if (depth > 10) return
    if (!element) {
      return
    } else if (element.toDate) {
      return
    } if (typeof element === 'string') {
      if (element.match(varRegex)) element.match(varRegex).forEach(v => vars[v.split('{{')[1].split('}}')[0].trim()] = null)
    } else if (element instanceof Array) {
      for (const val of element) {
        deepFind(val, depth + 1)
      }
    } else if (typeof element === 'object') {
      Object.values(element).forEach(val => deepFind(val, depth + 1))
    }
  }

  deepFind(task.Components, 0)

  Object.keys(vars).forEach(key => {
    if (key.startsWith('@Task')) {
      const [, elmIndex, type] = key.split('.')
      const index = elmIndex.length === 8 ? Object.values(task.Components || {}).findIndex(e => e.ComponentID === elmIndex) : elmIndex - 1
      vars[key] = getValue(task.Components?.[index], type)
    } else {
      delete vars[key]
    }
  })
  return vars
}

export async function templateValueVariables(task, clientSession) {
  if (!task.Components) return {}
  const vars = {}
  const tasksData = {}

  Object.values(clientSession?.client?.Metadata?.Sections || {}).forEach(section => {
    Object.values(section?.Data || {}).forEach(task => {
      tasksData[task.TaskID] = { ref: task.Ref, data: null }
    })
  })

  function deepFind(element, depth) {
    if (depth > 10) return
    if (!element) {
      return
    } else if (element.toDate) {
      return
    } if (typeof element === 'string') {
      if (element.match(varRegex)) element.match(varRegex).forEach(v => vars[v.split('{{')[1].split('}}')[0].trim()] = null)
    } else if (element instanceof Array) {
      for (const val of element) {
        deepFind(val, depth + 1)
      }
    } else if (typeof element === 'object') {
      Object.values(element).forEach(val => deepFind(val, depth + 1))
    }
  }
  deepFind(task.Components, 0)

  await Promise.all(Object.keys(vars).map(async key => {
    if (key.startsWith('@Template')) {
      const [, TaskID, elmIndex, type] = key.split('.')
      if (!tasksData[TaskID]) return
      if (!tasksData[TaskID].data) tasksData[TaskID].data = (await clientSession.clientRef.collection('Templates').doc(tasksData[TaskID].ref).get()).data() || {}
      const index = elmIndex.length === 8 ? Object.values(tasksData[TaskID].data?.Components || {})?.findIndex(e => e.ComponentID === elmIndex) : elmIndex - 1
      vars[key] = getValue(tasksData[TaskID].data?.Components?.[index], type)
    } else {
      delete vars[key]
    }
  }))

  return vars
}

function getValue(component, type) {
  if (!component?.[type]) return component?.[type]
  if (component.Type === 'fileUpload' && type === 'Value') {
    const out = {}
    Object.keys(component[type]).forEach(key => {
      const value = component[type][key]
      out[key] = value.map ? value.map(e => e?.Download ?? e) : []
    })
    return out
  }
  return component[type]
}