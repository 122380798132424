import { Drawer, Typography, TextField, InputAdornment, IconButton, makeStyles, List, ListItem, ListItemText, ListItemIcon, Tooltip } from '@material-ui/core'
import { useClient } from '../session/clientSession'
import { FileCopy, Send, Delete, Mail, RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons'
import { useSession } from '../session/session'
import { Form } from 'react-final-form'
import { Switches, TextField as TextInput, Select } from 'mui-rff'
import { OnChange } from 'react-final-form-listeners'
import { firebase } from '@firebase/app'
import '@firebase/firestore'
import { useState, useEffect } from 'react'
import { stringToSearchArray } from '../../utils/utils'
import ClientPermissions from './clientPermissions'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: '20px'
  },
  drawerWidth: {
    padding: '10px',
    [theme.breakpoints.up('md')]: {
      width: '500px',
    },
  },
  iconButton: {
    minWidth: '0px'
  }
}))

export default function ClientShare({ openShare, setOpenShare }) {
  const classes = useStyles()
  const session = useSession()
  const clientSession = useClient()
  const [internalTeam, setInternalTeam] = useState([])

  useEffect(() => {
    if (!clientSession.client || !session.teamProfiles || session.external) return
    setInternalTeam(Object.keys(session.teamProfiles).map(email => {
      if (clientSession.client.TeamArray?.includes(email)) return null
      return {
        label: <Tooltip title={email}><span>{session.teamProfiles?.[email]?.Name?.First ?? email} {session.teamProfiles?.[email]?.Name?.Last}</span></Tooltip>,
        value: {
          email,
          disableNotifications: session.teamProfiles?.[email]?.DisableNotifications
        }
      }
    }).filter(e => e))
  }, [clientSession.client, session.external, session.teamProfiles])

  if (!clientSession.client) return <></>

  const clientTeam = Object.keys(clientSession?.client?.ClientTeam || {}).filter(e => e != 'public')

  return <Drawer anchor={'right'} open={openShare} onClose={() => setOpenShare(false)}>
    <div className={classes.drawerWidth}>
      <Typography variant="h5">Sharing and Permissions</Typography>

      <TextField label="Hub Link" id='shareLinkAddress' value={`${location.origin}/onboarding/${clientSession?.clientRef?.id}`} disabled fullWidth variant='outlined' className={classes.spacing}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => {
                const link = document.querySelector('#shareLinkAddress')
                link.disabled = false
                link.select()
                link.disabled = true
                document.execCommand('copy')
                session.snackbar.setSnackbar({ message: 'Link Copied' })
                window.analytics.track('hub link copied', {
                  event_id: clientSession.clientRef.id,
                  org_id: session.org.ref.id
                })
              }}>
                <FileCopy />
              </IconButton>
            </InputAdornment>
        }} />

      {!session.external && <Form
        onSubmit={() => { }}
        initialValues={{ public: !!clientSession?.client?.ClientTeam?.public }}
        subscription={{}}
        render={() => <div style={{ textAlign: 'center' }}>
          <Switches name='public' data={{ label: 'Allow Link Sharing' }} />
          <OnChange name='public'>{val => {
            const share = { ClientTeam: {} }
            if (val) share.ClientTeam.public = { 'InviteSent': true }
            else share.ClientTeam.public = firebase.firestore.FieldValue.delete()
            clientSession.saveClient(share)
          }}</OnChange>
          {clientSession?.client?.ClientTeam?.public && <Tooltip title={'Change View/Edit Permissions for Link Share'}>
            <ListItemIcon style={{ marginTop: '5px' }} className={classes.iconButton}>
              <ClientPermissions email={'public'} />
            </ListItemIcon>
          </Tooltip>}
        </div>} />
      }

      <Typography variant="h6" className={classes.spacing}>Shared With:</Typography>
      {clientTeam.length > 0 &&
        <List>
          {clientTeam.sort().map((email, i) =>
            <ListItem key={i} dense divider>
              <ListItemText>
                {email}
              </ListItemText>
              {!session.external && <>
                <Tooltip title={`Change View/Edit Permissions for ${email}`}>
                  <ListItemIcon className={classes.iconButton}>
                    <ClientPermissions email={email} />
                  </ListItemIcon>
                </Tooltip>
                <Tooltip title={`Resend invite email to ${email}`}>
                  <ListItemIcon className={classes.iconButton}>
                    <IconButton size='small' onClick={() => {
                      const share = { ClientTeam: {} }
                      share.ClientTeam[email] = firebase.firestore.FieldValue.delete()
                      clientSession.saveClient(share)
                      setTimeout(() => {
                        share.ClientTeam[email] = { 'InviteSent': true }
                        session.snackbar.setSnackbar({ message: `Invite resent to ${email}` })
                        clientSession.saveClient(share)
                      }, 200)
                      window.analytics.track('invite resent', {
                        event_id: clientSession.clientRef.id,
                        org_id: session.org.ref.id
                      })
                    }}>
                      <Mail fontSize='small' />
                    </IconButton>
                  </ListItemIcon>
                </Tooltip>
                <Tooltip title={`Remove ${email}`}>
                  <ListItemIcon className={classes.iconButton}>
                    <IconButton size='small' onClick={() => {
                      const share = { ClientTeam: {} }
                      share.ClientTeam[email] = firebase.firestore.FieldValue.delete()
                      session.snackbar.setSnackbar({ message: `${email} removed` })
                      clientSession.saveClient(share)
                      window.analytics.track('external collaborator removed', {
                        event_id: clientSession.clientRef.id,
                        org_id: session.org.ref.id
                      })
                    }}>
                      <Delete fontSize='small' />
                    </IconButton>
                  </ListItemIcon>
                </Tooltip>
              </>}
            </ListItem>
          )}
        </List>
      }

      <Form
        onSubmit={() => { }}
        initialValues={{ emails: '' }}
        render={({ values, form }) => <div style={{ textAlign: 'center' }} className={classes.spacing}>
          <TextInput name='emails' type='email' label='Add Collaborator Email(s)...' variant='outlined'
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => {
                    const share = { ClientTeam: {}, Metadata: { Variables: {} } }
                    const emails = values?.emails?.split(',').map(e => e.trim().toLowerCase()).filter(e => validateEmail(e)) ?? []

                    emails.forEach(email => {
                      console.log(email)
                      share.ClientTeam[email] = { 'InviteSent': true }
                    })

                    share.Metadata.Variables['Client_Emails'] = [... new Set([...Object.keys(clientSession.client.ClientTeam || {}), ...emails])].filter(e => e != 'public').join(', ')
                    form.change('emails', undefined)
                    session.snackbar.setSnackbar({ message: `${emails.join(', ')} added` })
                    clientSession.saveClient(share)
                    if (session.external) {
                      window.analytics.track('external collaborator added by client', {
                        event_id: clientSession.clientRef.id,
                        org_id: session.org.ref.id
                      })
                    } else {
                      window.analytics.track('external collaborator added', {
                        event_id: clientSession.clientRef.id,
                        org_id: session.org.ref.id
                      })
                    }
                  }}>
                    <Send />
                  </IconButton>
                </InputAdornment>
            }} />
        </div>} />

      {!session.external && <>
        <Typography variant="h6" className={classes.spacing}>Internal Team:</Typography>
        <List>
          {(clientSession.client?.TeamArray ?? []).filter(e => e).sort().map((email, i) => {
            const isPrimaryOwner = session.teamProfiles?.[email]?.Calendly && clientSession.client?.Metadata?.Variables?.Primary_Scheduling_Link === session.teamProfiles?.[email]?.Calendly
            return <ListItem key={i} dense divider>
              <ListItemText>
                <Tooltip title={email}><span>{session.teamProfiles?.[email]?.Name?.First ?? email} {session.teamProfiles?.[email]?.Name?.Last}</span></Tooltip>
              </ListItemText>
              <Tooltip title={isPrimaryOwner ? '' : 'Set as primay account owner'}>
                <ListItemIcon className={classes.iconButton}>
                  <IconButton size='small' onClick={() => {
                    if (session.teamProfiles?.[email]?.Calendly) clientSession.saveClient({
                      Metadata: {
                        Variables: {
                          'Primary_Scheduling_Link': session.teamProfiles?.[email]?.Calendly ?? '',
                          'Primary_Agent_First_Name': session.teamProfiles?.[email]?.Name?.First ?? '',
                          'Primary_Agent_Last_Name': session.teamProfiles?.[email]?.Name?.Last ?? '',
                          'Primary_Agent_Email': session.teamProfiles?.[email]?.Email ?? '',
                        }
                      }
                    })
                    else session.snackbar.setSnackbar({ message: <span>{session.teamProfiles?.[email]?.Name?.First ?? email} {session.teamProfiles?.[email]?.Name?.Last} does not have a Calendly / Scheduling link set.<br />Primary Account Owner requires a Calendly / Scheduling link.</span>, severity: 'error', duration: '10000' })
                    window.analytics.track('primary internal set', {
                      event_id: clientSession.clientRef.id,
                      org_id: session.org.ref.id
                    })
                  }}>
                    {isPrimaryOwner ? <RadioButtonChecked fontSize='small' /> : <RadioButtonUnchecked fontSize='small' />}
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
              <Tooltip title={`${clientSession.client?.InternalTeam?.[email]?.DisableNotifications ? 'En' : 'Dis'}able notifications for ${email}`}>
                <ListItemIcon className={classes.iconButton}>
                  <IconButton size='small' onClick={() => {
                    if (!clientSession.client.InternalTeam) clientSession.client.InternalTeam = {}
                    if (!clientSession.client.InternalTeam[email]) clientSession.client.InternalTeam[email] = {}
                    clientSession.client.InternalTeam[email].DisableNotifications = !clientSession.client.InternalTeam[email].DisableNotifications
                    clientSession.saveClient({ InternalTeam: clientSession.client.InternalTeam })
                    window.analytics.track('internal collaborator notification changed', {
                      event_id: clientSession.clientRef.id,
                      org_id: session.org.ref.id
                    })
                  }}>
                    <Mail fontSize='small' style={{ opacity: `${clientSession.client?.InternalTeam?.[email]?.DisableNotifications ? '0.5' : '1'}` }} />
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
              <Tooltip title={`Remove ${email}`}>
                <ListItemIcon className={classes.iconButton}>
                  <IconButton size='small' onClick={() => {
                    const Search = stringToSearchArray([clientSession.client.Name, ...clientSession.client.TeamArray.filter(e => e != email)])
                    session.snackbar.setSnackbar({ message: `${email} removed` })
                    const Metadata = { Variables: { 'Internal_Emails': clientSession.client.TeamArray.filter(e => e != email).join(', ') } }
                    if (isPrimaryOwner) {
                      Metadata.Variables.Primary_Scheduling_Link = ''
                      Metadata.Variables.Primary_Agent_First_Name = ''
                      Metadata.Variables.Primary_Agent_Last_Name = ''
                      Metadata.Variables.Primary_Agent_Email = ''
                    }
                    clientSession.saveClient({ Metadata, Search, TeamArray: firebase.firestore.FieldValue.arrayRemove(email) })
                    window.analytics.track('internal collaborator removed', {
                      event_id: clientSession.clientRef.id,
                      org_id: session.org.ref.id
                    })
                  }}>
                    <Delete fontSize='small' />
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          }
          )}
        </List>
        <Form
          onSubmit={() => { }}
          initialValues={{ emails: '' }}
          render={({ form }) => <div style={{ textAlign: 'center' }} className={classes.spacing}>
            <Select name='emails' label='Add Internal Team Member' variant='outlined' data={internalTeam} />
            <OnChange name='emails'>{({ email, disableNotifications }) => {
              if (!email) return
              if (!clientSession.client.InternalTeam) clientSession.client.InternalTeam = {}
              if (!clientSession.client.InternalTeam[email]) clientSession.client.InternalTeam[email] = {}
              clientSession.client.InternalTeam[email].DisableNotifications = disableNotifications ?? false
              form.change('emails', undefined)
              if (!clientSession.client.TeamArray) clientSession.client.TeamArray = []
              const Search = stringToSearchArray([(clientSession.client.Name || ''), ...clientSession.client.TeamArray, email])
              clientSession.saveClient({ InternalTeam: clientSession.client.InternalTeam, Search, Metadata: { Variables: { 'Internal_Emails': [...clientSession.client.TeamArray, email].join(', ') } }, TeamArray: firebase.firestore.FieldValue.arrayUnion(email) })
              session.snackbar.setSnackbar({ message: `${email} added` })
              window.analytics.track('internal collaborator added', {
                event_id: clientSession.clientRef.id,
                org_id: session.org.ref.id
              })
            }}</OnChange>
          </div>} />

      </>}

    </div>
  </Drawer >
}

ClientShare.propTypes = {
  openShare: PropTypes.bool.isRequired,
  setOpenShare: PropTypes.func.isRequired
}

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}