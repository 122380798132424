/* global process */
import React, { useState, useEffect, useContext, createContext } from 'react'
import Router, { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { firebase } from '@firebase/app'
import '@firebase/auth'
import '@firebase/firestore'
import { getScript } from '../../utils/utils.js'
import dayjs from 'dayjs'
import LogRocket from 'logrocket'

// eslint-disable-next-line no-undef
const config = (process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_VERSION_SHA === 'devsha') ? require('../../devConfig.json') : require('../../prodConfig.json')

const debug = true

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

const firestoreInstance = firebase.firestore()

if (typeof location != 'undefined') {
  if (location.host.split(':')[1] === '5000') firestoreInstance.settings({ host: 'localhost:8080', ssl: false }) // use local db for test server
  //else firestoreInstance.enablePersistence({ synchronizeTabs: true }).catch(e => console.log(e))
  firestoreInstance.settings({ experimentalAutoDetectLongPolling: true })
}

// eslint-disable-next-line no-undef
if (typeof analytics != 'undefined') analytics.load(config.segmentId)

const sessionContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useSession().
export function SessionProvider({ children }) {
  const auth = useProvideAuth()
  return <sessionContext.Provider value={auth}>{children}</sessionContext.Provider>
}
SessionProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSession = () => {
  return useContext(sessionContext)
}


// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [org, setOrg] = useState(null)
  const [profile, setProfile] = useState(null)
  const [external, setExternal] = useState(null)
  const [saved, setSaved] = useState(true)
  const [header, setHeader] = useState(null)
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const [notPartOfTeam, setNotPartOfTeam] = useState(false)

  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [snackbarDuration, setSnackbarDuration] = useState(null)

  const [teamProfiles, setTeamProfiles] = useState(null)
  const [taskCache, setTaskCache] = useState(null)
  const [templateCache, setTemplateCache] = useState(null)

  const router = useRouter()

  function setSnackbar({ severity = 'success', message = '', duration = 3000, show = true }) {
    setShowSnackbar(show)
    setSnackbarMessage(message)
    setSnackbarSeverity(severity)
    setSnackbarDuration(duration)
  }

  const onVisibilityChange = () => {
    setIsVisible(!document['hidden'])
    if (document['hidden'] && window.versionSHA != window.originalVersionSHA && !external) {
      location.reload()
    }
  }

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signout = (url) => {
    localStorage.removeItem('userEmail')
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false)
        const [, domainName,] = location.hostname.split('.')
        const isdev = domainName === 'localtest'
        const signoutURL = 'https://simplemnt.com/r?url=' + encodeURI(url || org?.data?.SignOutURL || 'https://simplemnt.com')
        const singoutHook = isdev ? 'horatio-test.auth0' : 'login.simplemnt'
        location.replace(`https://${singoutHook}.com/v2/logout?returnTo=${encodeURI(signoutURL)}`)
      })
  }

  const updateProfile = (newProfileData) => {
    setSaved(false)
    profile.ref.set(newProfileData, { merge: true }).then(() => {
      setSaved(true)
    }, error => console.error('profile set', error))
  }

  const updateOrg = async (orgData) => {
    setSaved(false)
    await org.ref.set(orgData)
    setSaved(true)
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.originalVersionSHA = process.env.NEXT_PUBLIC_VERSION_SHA
    return firestoreInstance.collection('Version').doc('CurrentVersion').onSnapshot(versionSnap => {
      if (!versionSnap.exists) return
      window.versionSHA = versionSnap.data().sha
    })
  }, [])

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    if (typeof window === 'undefined') return
    if (external === null) {
      if (location.pathname.startsWith('/onboarding')) {
        setExternal(true)
        return
      } else {
        setExternal(false)
        return
      }
    }

    const [subdomainName, domainName, tld] = location.hostname.split('.')
    const subdomain = domainName === 'simplemnt' || domainName === 'localtest' ? subdomainName : domainName
    const domain = `.${domainName}.${tld}`
    const isdev = domainName === 'localtest'
    const domainPort = isdev ? ':3000' : ''
    const loginUrl = `${subdomainName}${domain}${domainPort}/`, finalRedirect = `${isdev ? 'http' : 'https'}://${loginUrl}`
    let finalAuthResult = { idTokenPayload: {} }

    const login = async () => {
      let token = {
        message: 'No authorization token was found'
      }
      if (window.location.hash === '') {
        const response = await fetch('/api/login', {
          headers: {
            'subdomain': subdomain,
            'external': external,
            'path': location.pathname
          },
        })
        token = await response.json()
      }
      if (debug) console.log('TOKEN FROM ENDPOINT', token)
      if (token.message && token.message === 'No authorization token was found' || token.authResult && token.authResult.idTokenPayload.email === 'public' && !external) {
        if (window.location.host + window.location.pathname != loginUrl) {
          sessionStorage.setItem('originalUrl', window.location.pathname)
        }
        const webAuth = await setWebAuth()
        //console.time('Auth0 Parse')
        webAuth.parseHash(async (err, authResult) => {
          if (debug) console.log('AUTH0 RESULT', authResult)
          //console.timeEnd('Auth0 Parse')
          //console.time('Auth0 Check')
          if (authResult && authResult.accessToken && authResult.idToken) {
            window.location.hash = ''
            const response = await fetch('/api/login', {
              headers: {
                'Authorization': `Bearer ${authResult.idToken}`,
                'authresult': JSON.stringify(authResult),
                'subdomain': subdomain,
                'external': external,
                'path': location.pathname
              },
            })
            const token = await response.json()
            if (debug) console.log('TOKEN FROM ENDPOINT AFTER AUTH0', token)
            //console.timeEnd('Auth0 Check')
            loginFirebase(authResult, token.data, token.token)
          } else if (err) {
            if (debug) console.log(err)
          } else {
            //console.log(161, 'authorize')
            webAuth.authorize()
          }
        })
      } else if (token.token) {
        loginFirebase(token.authResult, token.data, token.token)
      }
      async function setWebAuth() {
        //console.time('get webauth')
        const subdomainData = await firestoreInstance.collection('Orgs').where('Domain', '==', subdomain).limit(1).get()
        let clientID = config.auth0ClientId
        if (!subdomainData.empty) {
          clientID = (subdomainData.docs[0].data()).Auth0ClientID || clientID
        }
        //console.log(171, clientID)
        //console.timeEnd('get webauth')
        await getScript('https://cdn.auth0.com/js/auth0/9.12.0/auth0.min.js')
        // eslint-disable-next-line no-undef
        return new auth0.WebAuth({
          domain: config.auth0Domain,
          clientID,
          responseType: 'token id_token',
          scope: 'openid profile email',
          redirectUri: finalRedirect,
          leeway: 300
        })
      }
    }

    async function loginFirebase(authResult, data, firebaseToken) {
      if (debug) console.log('LOGIN FIREBASE', data)
      finalAuthResult = authResult
      firebase.auth().signInWithCustomToken(firebaseToken)
    }

    const unsubscribe = []
    let orgUnsub = () => { }
    let profileUnsub = () => { }
    unsubscribe.push(orgUnsub, profileUnsub)
    unsubscribe.push(firebase.auth().onIdTokenChanged(async firebaseUser => {
      //console.time('login finished')
      if (debug) console.log('FIREBASE USER', firebaseUser)
      if (firebaseUser) {
        window.analytics.identify(firebaseUser.uid, { groupId: location.hostname.split('.')[0] })
        window.analytics.track('signed in', {
          account_name: firebaseUser.uid,
          external: external
        }, {
          context: {
            groupId: location.hostname.split('.')[0]
          }
        })
        setUser(firebaseUser)
        if (!org) {
          orgUnsub()
          orgUnsub = firestoreInstance.collection('Orgs').where('Domain', '==', subdomain).limit(1).onSnapshot(orgSnap => {
            if (!orgSnap.empty) {
              setOrg({
                data: orgSnap.docs[0].data(),
                ref: orgSnap.docs[0].ref
              })
              if (firebaseUser.uid) {
                console.log('FIREBASE UID', firebaseUser.uid)
                if (typeof window != 'undefined' && window.FS.identify && typeof window.FS.identify === 'function' && firebaseUser.email && !process.env.NODE_ENV === 'development') {

                  try {
                    LogRocket.init('nozf6g/simplemnt-prod')
                    LogRocket.identify(firebaseUser.uid, {
                      name: firebaseUser.displayName,
                      email: firebaseUser.uid
                    })

                    window.FS.identify(firebaseUser.uid, {
                      displayName: firebaseUser.displayName,
                      email: firebaseUser.uid,
                    })
                  } catch (e) {
                    console.error(e)
                  }

                }
                profileUnsub()
                if (!external) {
                  profileUnsub = orgSnap.docs[0].ref.collection('Users').doc(firebaseUser.uid).onSnapshot(profileSnap => {
                    if (!profileSnap.empty) {
                      try {
                        const orgProfile = profileSnap.data()
                        if (debug) console.log('Profile', orgProfile)
                        // On first login, set the profile picture if we have one
                        if (!orgProfile.Picture) {
                          profileSnap.ref.set({ Picture: finalAuthResult.idTokenPayload.picture || '' }, { merge: true })
                          orgProfile.Picture = finalAuthResult.idTokenPayload.picture || ''
                        }
                        if (!orgProfile.Role) {
                          profileSnap.ref.set({ Role: ['User'] }, { merge: true })
                          orgProfile.Role = ['User']
                        }
                        if (orgProfile.Admin) {
                          firebase.auth().currentUser.getIdTokenResult().then(async idTokenResult => {
                            if (!idTokenResult.claims.admin) {
                              console.log('not admin!')
                              firebase.auth().signOut().then(() => location.reload())
                            } else if (!orgProfile.Role.includes('Admin')) {
                              orgProfile.Role.push('Admin')
                              profileSnap.ref.set({ Role: orgProfile.Role }, { merge: true })
                            }
                          })
                        }
                        setProfile({
                          data: orgProfile,
                          ref: profileSnap.ref
                        })
                        if (!orgProfile.Name?.First || !orgProfile.Name?.Last || !orgProfile.Title) {
                          setTimeout(() => Router.push('/profile'), 500)
                        }
                      } catch (error) {
                        //setNotPartOfTeam(true)
                        console.error('set org profile', error)
                      }
                    }
                  }, error => {
                    setNotPartOfTeam(true)
                    firebase.auth().currentUser.getIdTokenResult().then(idTokenResult => console.log(idTokenResult))
                    console.error('read org profile', error)
                  })
                }
              } else if (!external) {
                signout(location.href)
              }
            }
          }, error => console.error('org', error))
        }
        const originalURL = sessionStorage.getItem('originalUrl')
        if (originalURL) {
          //console.log(`Original URL: ${originalURL}`)
          sessionStorage.removeItem('originalUrl')
          Router.replace(originalURL)
        }
        //console.timeEnd('login finished')
      } else if (user === null) {
        setUser(false)
        login()
      }
    }))

    window.addEventListener('visibilitychange', onVisibilityChange, false)
    unsubscribe.push(() => window.removeEventListener('visibilitychange', onVisibilityChange))

    // Cleanup subscription on unmount
    return () => unsubscribe.forEach(e => e())
  }, [external, org])

  const updateSnapshotCache = (snap, update) => {
    const collection = {}
    snap.docs.forEach(e => collection[e.ref.id] = e.data())
    update(collection)
  }

  useEffect(() => {
    if (!org?.ref || external) return
    return org.ref.collection('Users').onSnapshot(snap => updateSnapshotCache(snap, setTeamProfiles))
  }, [external, org])

  useEffect(() => {
    if (!org?.ref || external) return
    return org.ref.collection('Tasks').onSnapshot(snap => updateSnapshotCache(snap, setTaskCache))
  }, [external, org])

  useEffect(() => {
    if (!org?.ref || external) return
    return org.ref.collection('Templates').onSnapshot(snap => updateSnapshotCache(snap, setTemplateCache))
  }, [external, org])

  // Billing Check
  useEffect(() => {
    if (external || !org?.data?.Billing?.ExpTime) return
    const expDate = dayjs(org.data?.Billing?.ExpTime?.toDate())
    if (expDate.isBefore(dayjs())) {
      const pageName = router.pathname.split('/').pop()
      if (pageName != 'account-expired') {
        console.log('Account Expired!')
        router.replace('/account-expired')
      }
    }
  }, [external, org, router])

  // Return the user object and auth methods
  return {
    user,
    setUser,
    org,
    profile,
    external,
    signout,
    updateProfile,
    saved,
    setSaved,
    header,
    setHeader,
    mobileSidebarOpen,
    setMobileSidebarOpen,
    isVisible,
    updateOrg,
    notPartOfTeam,
    teamProfiles,
    taskCache,
    templateCache,
    snackbar: {
      showSnackbar,
      snackbarMessage,
      snackbarSeverity,
      snackbarDuration,
      setSnackbar,
    },
    firestoreInstance
  }
}
