import { useState } from 'react'
import Link from 'next/link'
import Router, { useRouter } from 'next/router'
import { fade, makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Menu, MenuItem, IconButton, Avatar, Typography } from '@material-ui/core'
import { useSession } from '../session/session.js'
import SaveIndicator from './saveIndicator.js'
import { internalRoles } from 'utils/internalRoles'
import { setStyle } from 'utils/utils'
import { AccountCircle, ArrowBackIos, Edit } from '@material-ui/icons'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import { OnBlur } from 'react-final-form-listeners'
import HelpNotification from 'components/notification/helpNotification.js'
import dayjs from 'dayjs'


const useStyles = makeStyles((theme) => ({
  icon: {
    paddingRight: theme.spacing(2),
  },
  link: {
    fontSize: '1.1rem',
    textDecoration: 'none',
    color: fade(theme.palette.common.white, 0.50),
    padding: theme.spacing(1),
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  header: {
    display: 'contents',
    '& input.MuiInputBase-input': {
      color: fade(theme.palette.common.white, 0.85),
    },
    '& .editButton': {
      visibility: 'hidden',
      paddingLeft: '5px',
    },
    '&:hover .editButton': {
      visibility: 'visible'
    }
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  seletedLink: {
    color: fade(theme.palette.common.white, 0.95),
  },
}))

export default function TopAppBar() {
  const session = useSession()
  const classes = useStyles()
  const [profileMenuAnchor, setAnchorEl] = useState(null)
  const [mobileMenuId, setMobileMenu] = useState(null)
  const [editing, setEditing] = useState(false)

  const router = useRouter()

  if (session.org?.data) setStyle(session.org.data, true, session.external)

  let countdown
  if (session?.org?.data?.Billing?.ExpTime) {
    const diff = dayjs(session?.org?.data?.Billing?.ExpTime.toDate()).diff(dayjs(), 'day')
    if (diff < 15) countdown = `Trial expires in: ${diff} days`
    if (diff < 1) countdown = 'Trial expired'
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleProfileMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMenu(event.currentTarget)
  }
  const handleMobileMenuClose = () => {
    setMobileMenu(null)
  }

  const profileMenuId = 'account-menu'
  const renderProfileMenu = (
    <Menu
      anchorEl={profileMenuAnchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={!!profileMenuAnchor}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={() => {
        handleProfileMenuClose()
        Router.push('/profile')
      }}>Edit Profile</MenuItem>
      <MenuItem onClick={() => {
        handleProfileMenuClose()
        session.signout()
      }}>Sign Out</MenuItem>
    </Menu>
  )

  const links = [
    { link: '/clients', title: 'Clients' },
  ]
  if (session?.profile?.data?.Role.some(e => internalRoles.EditTemplates.includes(e))) links.push({ link: '/templates', title: 'Templates' })
  if (session?.profile?.data?.Role.some(e => internalRoles.EditTasks.includes(e))) links.push({ link: '/tasks', title: 'Tasks' })
  links.push({ link: '/team', title: 'Team' })
  links.push({ link: '/analytics', title: 'Analytics' })
  if (session?.profile?.data?.Role.some(e => internalRoles.EditOrgSettings.includes(e))) links.push({ link: '/settings', title: 'Settings' })

  const profileMenu = (<>
    <IconButton onClick={handleMobileMenuOpen}><img src="/simplemnt_icon.svg" alt="logo" width='29px' height='40px' /></IconButton>
    <Menu
      onClose={handleMobileMenuClose}
      anchorEl={mobileMenuId}
      open={!!mobileMenuId}
      keepMounted
    >
      {links.map(link => <MenuItem key={link.link} onClick={() => {
        handleMobileMenuClose()
        Router.push(link.link)
      }}>{link.title}</MenuItem>)}
    </Menu>
  </>
  )
  return (
    <div className={classes.grow}>
      <AppBar position="fixed" elevation={0}><Toolbar>
        {router?.query?.embedded ? <></> : <>
          {router?.query?.preview ? <>Preview</> : <>

            {(!session.profile?.data?.Name?.First || !session.profile?.data?.Name?.Last || !session.profile?.data?.Title) ? <></> : <>
              {!session.header?.text && <div className={classes.sectionMobile}>{profileMenu}</div>}

              {!session.header?.text && <div className={classes.sectionDesktop}>
                <Link href='/'><a className={classes.icon}><img src="/simplemnt_icon.svg" alt="logo" width='32px' height='44px' /></a></Link>
                {links.map(link => <Link key={link.link} href={link.link}><a className={`${classes.link} ${'/' + location.pathname.split('/')[1] === link.link ? classes.seletedLink : ''}`}>{link.title}</a></Link>)}
              </div>}

              {session.header?.text && <Typography variant='h6' className={classes.header}>
                <IconButton color='inherit' onClick={() => {
                  Router.push(session.header.link)
                }}><ArrowBackIos /></IconButton>
                {editing ?
                  <Form
                    onSubmit={() => { }}
                    initialValues={{ Name: session.header.text }}
                    render={({ values }) => <>
                      <TextField name='Name' />
                      <OnBlur name='Name'>{() => {
                        session.header.edit(values.Name)
                        setEditing(false)
                      }}</OnBlur>
                    </>}
                  />
                  : <div onClick={() => setEditing(true)}>
                    {session.header.label} {session.header.text}
                    {session.header.edit && <IconButton size='small' color='inherit' className='editButton'><Edit fontSize='small' /></IconButton>}
                  </div>
                }
              </Typography>}
            </>}
            <div className={classes.grow} />
            {countdown && <span>{countdown}</span>}
            <SaveIndicator />
            <HelpNotification />
            <div>
              <IconButton
                edge="end"
                aria-label="profile"
                aria-controls={profileMenuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                {session.profile ?
                  <Avatar alt="Profile" src={session.profile.data.Picture} /> :
                  <AccountCircle fontSize="large" />
                }
              </IconButton>
            </div></>}
        </>}
      </Toolbar></AppBar>
      {renderProfileMenu}
    </div>
  )
}