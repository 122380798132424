import { Grid, IconButton, makeStyles, Popover, Tooltip } from '@material-ui/core'
import { ArrowBack, ArrowForward, HelpOutline } from '@material-ui/icons'
import { useState, useEffect, Fragment } from 'react'
import { useSession } from 'components/session/session'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: '2em'
  },
  image: {
    width: '100%',
    height: '200px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    borderRadius: '10px'
  },
  container: {
    padding: '5px',
    backgroundColor: theme.palette.grey['200']
  },
  text: {
    padding: '20px 5px'
  },
  helpMenu: {
    width: '300px',
  },
  helpMenuContainer: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '35px',
      right: '77px',
      borderBottom: '10px solid white',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderTop: '10px solid transparent',
    }
  }
}))


export default function HelpNotification() {
  const classes = useStyles()
  const session = useSession()
  const [image, setImage] = useState('')
  const [text, setText] = useState('')
  const [open, setOpen] = useState(false)
  const [originalDoc, setOriginalDoc] = useState(null)
  const [currentDoc, setCurrentDoc] = useState(null)

  useEffect(() => {
    if (!session.firestoreInstance || !session?.profile?.data?.Role) return
    session.firestoreInstance.collection('Tips').where('Active', '==', true).limit(1).get().then(snap => {
      const tip = snap?.docs?.[0]
      if (!tip) return
      const data = tip?.data() ?? {}
      setImage(data.Image || '')
      setText(data.Text || '')
      setCurrentDoc(tip)
      setOriginalDoc(tip)
      // Check to see if this tip was the last tip seen, if not pop it up automatically
      const lastSeenId = localStorage.getItem('LastSeenTip') || ''
      if (tip?.ref.id && lastSeenId != tip?.ref.id && session?.profile?.data?.Role?.includes('Admin')) { // Only pop messages to Admins for now
        localStorage.setItem('LastSeenTip', tip?.ref.id)
        setTimeout(() => setOpen(true), 200)
      }
    })
  }, [session.firestoreInstance, session?.profile?.data?.Role])

  function go(forward) {
    let q = session.firestoreInstance.collection('Tips').orderBy('Active', 'desc')
    if (forward) q = q.startAfter(currentDoc)
    else q = q.endBefore(currentDoc)
    q.limit(1).get().then(snap => {
      let tip = snap?.docs?.[0]
      if (!tip) tip = originalDoc
      const data = tip?.data() ?? {}
      setImage(data.Image || '')
      setText(data.Text || '')
      setCurrentDoc(tip)
    })
  }

  const menuId = 'help-menu'
  const menuButtonId = 'help-menu-button'
  return <>
    <Tooltip title='Help and Tips'><IconButton
      edge="end"
      aria-label="notifications"
      aria-controls={menuId}
      id={menuButtonId}
      aria-haspopup="true"
      onClick={() => setOpen(true)}
      color="inherit"
    >
      <HelpOutline />
    </IconButton></Tooltip>
    <Popover
      anchorEl={document.getElementById(menuButtonId)}
      id={menuId}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ className: classes.helpMenu }}
      className={classes.helpMenuContainer}
      transitionDuration={5}
    >
      <Grid container className={classes.container}>
        <Grid item xs={1}>{originalDoc?.ref?.id != currentDoc?.ref?.id && <IconButton size='small' edge='start' onClick={() => go(false)}><ArrowBack /></IconButton>}</Grid>
        <Grid item xs={10}><div className={classes.title}>Simplemnt Tips</div></Grid>
        <Grid item xs={1}><IconButton size='small' edge='end' onClick={() => go(true)}><ArrowForward /></IconButton></Grid>
        <Grid item xs={12}>
          <div style={{ backgroundImage: `url(${image})` }} className={classes.image}></div>
        </Grid>
        <Grid item xs={12} className={classes.text}>
          {text.split('<br>').map((e, i) => <Fragment key={i}>{e}<br /></Fragment>)}
        </Grid>
      </Grid>
    </Popover>
  </>
}