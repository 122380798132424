import cssVars from 'css-vars-ponyfill'
import { firebase } from '@firebase/app'
import '@firebase/firestore'

export function mapToArray(obj) {
  const out = []
  Object.keys(obj || {}).forEach(index => {
    while (out.length < index) out.push([])
    out[index] = obj[index]
  })
  return out
}
export function arrayToMap(array, prefix) {
  return array.reduce((map, cur, i) => {
    if (prefix) map[`${prefix}${i}`] = cur
    else map[i] = cur
    return map
  }, {})
}

export function stringToSearchArray(str) {
  console.log(str)
  if (!Array.isArray(str) && str) str = [str]
  const searchArray = []
  str.forEach(s => {
    s = s.toLowerCase()
    searchArray.push(s)
    const words = s.split(' ')
    const wordsGroups = []
    words.forEach((word, i) => {
      let newWord = ''
      for (let j = i; j < words.length; j++) {
        newWord = newWord + words[j] + ' '
      }
      newWord = newWord.trim()
      wordsGroups.push(newWord)
    })
    wordsGroups.forEach(word => {
      let cur = ''
      word.split('').forEach((letter, i) => {
        if (i > 15) return
        cur = cur + letter
        searchArray.push(cur)
      })
    })
  })
  return searchArray
}
export function setStyle(orgData, preview, external) {
  if (!orgData || typeof window === 'undefined') return
  if (!preview) document.querySelector('body').style.backgroundColor = orgData.Colors.Background
  if (external) {
    if (orgData.Colors.Favicon) {
      var link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = orgData.Colors.Favicon    //path to your icon
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    document.title = `${orgData.Name} Onboarding`
  }
  const root = document.documentElement
  if (!orgData.Colors.Font) orgData.Colors.Font = {}
  let cssVariables = {
    '--logo-background': orgData.Colors.LogoBackground,
    '--nav-background': orgData.Colors.NavBackground,
    '--nav-text': orgData.Colors.NavText,
    '--top-bar-text': orgData.Colors.TopBarText || orgData.Colors.NavText,
    '--nav-highlight': orgData.Colors.NavHighlight,
    '--bar': orgData.Colors.Bar,
    '--main-background': orgData.Colors.Background,
    '--card-text': orgData.Colors.CardText || '#0000008A',
    '--button-color': orgData.Colors.Button,
    '--button-text-color': invertColor(orgData.Colors.Button, true),
    '--note-background': orgData.Colors.NoteBackground || orgData.Colors.NavBackground,
    '--note-text': orgData.Colors.NoteText || orgData.Colors.NavText
  }
  if (orgData.Colors.Font) {
    cssVariables['--font'] = orgData.Colors.Font.Name
    document.getElementsByTagName('head')[0].insertAdjacentHTML('beforeend', `<link rel="stylesheet" href="${orgData.Colors.Font.CSS}" />`)
  }
  for (let variable in cssVariables) root.style.setProperty(variable, cssVariables[variable])
  cssVars({
    variables: cssVariables,
    preserveStatic: false,
  })
  // Run again to style any dynamic classes
  setTimeout(() => {
    cssVars({
      variables: cssVariables,
      preserveStatic: false,
    })
  }, 1000)
}

export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.')
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF'
  }
  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b)
  function padZero(str, len) {
    len = len || 2
    var zeros = new Array(len).join('0')
    return (zeros + str).slice(-len)
  }
}

export function getScript(source, type = 'script') {
  return new Promise(res => {
    const hasScript = !![...document.getElementsByTagName(type)].find(script => (script.src === source || script.href === source) && script.dataset.loaded === 'true')
    if (hasScript) {
      res()
      return
    }
    let script = document.createElement(type)
    const prior = document.getElementsByTagName('script')[0]
    script.async = 1

    script.onload = script.onreadystatechange = (_, isAbort) => {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = script.onreadystatechange = null
        script.dataset.loaded = 'true'
        script = undefined

        if (!isAbort) setTimeout(res, 0)
      }
    }
    if (type === 'script') {
      script.src = source
    } else if (type === 'link') {
      script.rel = 'stylesheet'
      script.type = 'text/css'
      script.href = source
    }
    prior.parentNode.insertBefore(script, prior)
  })
}

export function clientSideTableFilter(searchTerm, e) {
  if (searchTerm === '') return true
  const nameLower = Object.values(e.search || e.cols || {}).filter(e => e?.toLowerCase).map(e => e.toLowerCase()).join(' ') // merge names from all columns
  const searchTerms = searchTerm.split(',').map(e => e.toLowerCase().trim()) // split search terms
  if (searchTerms.every(e => nameLower.search(e) > -1)) return true
  return false
}

export async function deleteAction(id, session, collection) {
  if (!session.org) return
  session.setSaved(false)
  await session.org.ref.collection(collection).doc(id).delete()
  session.setSaved(true)
  window.analytics.track(`${collection.toLowerCase().slice(0, -1)} deleted`, {
    event_id: id,
    org_id: session.org.ref.id
  })
}

export async function archiveAction(id, session, collection) {
  if (!session.org) return
  session.setSaved(false)
  await session.org.ref.collection(collection).doc(id).set({ Archive: true, ArchiveTime: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true })
  session.setSaved(true)
  window.analytics.track(`${collection.toLowerCase().slice(0, -1)} archived`, {
    event_id: id,
    org_id: session.org.ref.id
  })
}

export async function unarchiveAction(id, session, collection) {
  if (!session.org) return
  session.setSaved(false)
  await session.org.ref.collection(collection).doc(id).set({ Archive: false, ArchiveTime: null }, { merge: true })
  session.setSaved(true)
  window.analytics.track(`${collection.toLowerCase().slice(0, -1)} unarchived`, {
    event_id: id,
    org_id: session.org.ref.id
  })
}

export async function cloneAction(id, session, collection) {
  if (!session.org) return
  session.setSaved(false)
  const data = (await session.org.ref.collection(collection).doc(id).get()).data()
  data.Name = data.Name + ' - Clone - ' + Math.floor(Math.random() * 10000)
  const newItem = session.org.ref.collection(collection).doc()
  newItem.set(data)
  session.setSaved(true)
  window.analytics.track(`${collection.toLowerCase().slice(0, -1)} cloned`, {
    event_id: id,
    org_id: session.org.ref.id
  })
  return newItem.id
}

export function swapArray(list, iA, iB) {
  if (!list) list = [];
  [list[iA], list[iB]] = [list[iB], list[iA]]
  return list
}

export function reorderArray(list, iA, iB) {
  if (!list) list = []
  list.splice(iA, 0, list.splice(iB, 1)[0])
  return list
}

export function deleteArrayIndex(list, index) {
  if (!list) list = []
  return list.filter((_, i) => i != index)
}

export function addAtArrayIndex(newItem, list, index) {
  console.log(list)
  if (!list) list = []
  return [...list.slice(0, index), newItem, ...list.slice(index, list.length)]
}

export function addHTTP(url) {
  if (!url || !url.trim) return url
  url = url.trim()
  const pattern = /^((http|https|ftp):\/\/)/
  if (!pattern.test(url)) {
    url = 'https://' + url
  }
  return url
}

export function routeToPageWithParams(params, Router, replace) {
  const url = new URL(location.href)
  params.forEach(p => {
    if (p.value === null) url.searchParams.delete(p.name)
    else url.searchParams.set(p.name, p.value)
  })
  if (url.pathname.indexOf('clients') > 0) {
    if (replace) Router.replace('/clients/[clientid]' + url.search, url.pathname + url.search, { shallow: true })
    else Router.push('/clients/[clientid]' + url.search, url.pathname + url.search, { shallow: true })
  } else if (url.pathname.indexOf('templates') > 0) {
    Router.replace('/templates/[templateid]' + url.search, url.pathname + url.search, { shallow: true })
  } else if (url.pathname.indexOf('settings') > 0) {
    Router.replace('/settings' + url.search, url.pathname + url.search, { shallow: true })
  } else if (url.pathname.indexOf('tasks') > 0) {
    Router.replace('/tasks/[taskid]' + url.search, url.pathname + url.search, { shallow: true })
  }
}