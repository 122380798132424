import { makeStyles, Typography } from '@material-ui/core'
import { useSession } from 'components/session/session'
import { useEffect } from 'react'
import { useClient } from 'components/session/clientSession'

const useStyles = makeStyles((theme) => ({
  errorHolder: {
    margin: '100px 30px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '300px',
    },
  },
}))

export default function ClientPermissionError() {
  const classes = useStyles()
  const session = useSession()
  const clientSession = useClient()


  useEffect(() => {
    if (localStorage.getItem('userEmail') && !clientSession?.client?.ClientTeam?.public) {
      session.signout()
    }
  }, [clientSession, session])

  return <div id='error' className={classes.errorHolder}>
    <Typography variant='h3'>This On-Boarding Hub has not been shared with {localStorage.getItem('userEmail') || session.user.email || 'you'}.</Typography>
    <br /><br />
    <Typography variant='h3'>Please ask your contact to share this hub with you</Typography>
  </div>
}