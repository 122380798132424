import { useSession } from './session/session.js'
import { makeStyles } from '@material-ui/core/styles'
import Router from 'next/router'
import PropTypes from 'prop-types'
import { useClient } from './session/clientSession.js'
import { useState, useEffect } from 'react'
import ClientPermissionError from './client/clientPermissionError.js'
import InvalidSession from './session/invalidSession.js'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  maincontent: {
    marginTop: '60px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '256px',
      width: 'calc( 100% - 265px )'
    },
  },
}))

export default function MainContent(props) {
  const { Component, pageProps } = props
  const classes = useStyles()
  const session = useSession()
  const clientSession = useClient()
  const [currentPage, setCurrentPage] = useState('')

  useEffect(() => {
    if (!session.org) return
    changePage(location.pathname)
    Router.events.on('routeChangeComplete', changePage)
  }, [session.org])

  const snackbar = <Snackbar open={session.snackbar.showSnackbar} onClose={() => session.snackbar.setSnackbar({ show: false })} autoHideDuration={session.snackbar.snackbarDuration}><Alert onClose={() => session.snackbar.setSnackbar({ show: false })} elevation={6} variant="filled" severity={session.snackbar.snackbarSeverity}>{session.snackbar.snackbarMessage}</Alert></Snackbar>

  if (clientSession.clientError) return <ClientPermissionError />
  if (!session?.user?.uid) return <></>
  if (location.pathname === '/onboarding/simplemnt') {
    return <>
      <Component {...pageProps} />
      {snackbar}
    </>
  }
  if (session.external) return <div className={classes.maincontent}>
    <Component {...pageProps} />
    {snackbar}
  </div>

  if (session.notPartOfTeam) return <InvalidSession />
  return <div style={{ marginBottom: '64px' }}>
    <Component {...pageProps} />
    {snackbar}
  </div>

  function changePage(page) {
    if (window.versionSHA != window.originalVersionSHA && window.initialLoadComplete) {
      location.reload()
    }
    if (page != currentPage) {
      setCurrentPage(page)
      window.initialLoadComplete = true
      const clientId = clientSession?.clientRef?.id ?? ''
      const analyticsProps = {
        properties: {
          client_id: clientId,
          org_id: session?.org?.ref?.id ?? ''
        }
      }
      const analytics = window.analytics

      if (page.startsWith('/teams')) {
        analytics.page('teams', analyticsProps)
        return
      }
      if (page.startsWith('/clients')) {
        const clientID = page.split('/')[2]
        if (clientID) {
          analytics.page('client', analyticsProps)
        } else {
          analytics.page('clients', analyticsProps)
        }
        return
      }
      if (page.startsWith('/tasks')) {
        const taskID = page.split('/')[2]
        if (taskID) {
          analytics.page('task', analyticsProps)
        } else {
          analytics.page('tasks', analyticsProps)
        }
        return
      }
      if (page.startsWith('/templates')) {
        const templateID = page.split('/')[2]
        if (templateID) {
          analytics.page('template', analyticsProps)
        } else {
          analytics.page('templates', analyticsProps)
        }
        return
      }
      if (page.startsWith(`/onboarding/${clientId}/task`)) {
        analytics.page('onboarding task', analyticsProps)
        return
      }
      if (page.startsWith(`/onboarding/${clientId}/section`)) {
        analytics.page('onboarding section', analyticsProps)
        return
      }
      if (page.startsWith(`/onboarding/${clientId}/team`)) {
        analytics.page('onboarding team', analyticsProps)
        return
      }
      if (page.startsWith('/onboarding/new')) {
        analytics.page('hub creator', analyticsProps)
        return
      }
      if (page.startsWith(`/onboarding/${clientId}`)) {
        analytics.page('onboarding overview', analyticsProps)
        return
      }
      if (page.startsWith('/profile')) {
        if (page.split('/').length > 2) {
          analytics.page('view profile', analyticsProps)
          return
        }
        analytics.page('profile', analyticsProps)
        return
      }
      if (page.startsWith('/analytics')) {
        analytics.page('analytics', analyticsProps)
        return
      }
      if (page.startsWith('/settings')) {
        analytics.page('settings', analyticsProps)
        return
      }
    }
  }

}

MainContent.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object
}