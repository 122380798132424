export const internalRoles = {
  EditAllClients: ['Admin', 'EditAllClients'],
  EditUserProfile: ['Admin', 'EditUserProfile'],
  EditOrgSettings: ['Admin', 'EditOrgSettings'],
  EditTasks: ['Admin', 'EditTasks', 'EditTemplates'],
  EditTemplates: ['Admin', 'EditTemplates'],
  ViewTeamAnalytics: ['Admin', 'ViewTeamAnalytics'],
  CreateClients: ['Admin', 'User']
}

export const internalRoleMappings = {
  'Admin (All Permissions)': 'Admin',
  'View / Edit All Clients': 'EditAllClients',
  'Edit Tasks': 'EditTasks',
  'Edit Templates': 'EditTemplates',
  'View Team Analytics': 'ViewTeamAnalytics',
  'Edit Team Profiles': 'EditUserProfile',
  'Edit Org Settings': 'EditOrgSettings',
}