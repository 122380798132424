import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, IconButton } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { CloudUpload, CloudDone } from '@material-ui/icons/'
import { useSession } from '../session/session.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 6,
    left: 5,
    zIndex: 1,
  },
  externalProgress: {
    color: 'var(--nav-highlight) !important'
  },
  externalIcon: {
    color: 'var(--nav-text) !important'
  }
}))

export default function SaveIndicator() {
  const classes = useStyles()
  const session = useSession()

  const [hide, setHide] = React.useState(true)
  const [saved, setSaved] = React.useState(true)

  const buttonClassname = clsx({
    [classes.buttonSuccess]: session.saved,
  })


  if (session.saved) {
    setTimeout(() => {
      if (!saved) setSaved(true)
    }, 500)
    setTimeout(() => {
      if (!hide) setHide(true)
    }, 2500)
  } else {
    if (saved) setSaved(false)
    if (hide) setHide(false)
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper} style={{ display: hide ? 'none' : '' }}>
        <IconButton
          aria-label="save"
          color="inherit"
          className={`${buttonClassname} ${session.external ? classes.externalIcon : ''}`}
        >
          {saved ? <CloudDone /> : <CloudUpload />}
        </IconButton>
        {!saved && <CircularProgress size={38} className={`${classes.fabProgress} ${session.external ? classes.externalProgress : ''}`} />}
      </div>
    </div>
  )
}