import { Typography, Card, CardContent, Container, Button, makeStyles } from '@material-ui/core'
import { useSession } from './session'

const useStyles = makeStyles((theme) => ({
  buttonBlue: {
    color: theme.palette.getContrastText(theme.palette.info.main),
    backgroundColor: theme.palette.info.main,
    marginRight: '10px',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    }
  }
}))

export default function InvalidSession() {
  const session = useSession()
  const classes = useStyles()
  return <Container size='sm'>
    <Card id='error'>
      <CardContent>
        <Typography variant='h4'>Your account is not associated with this organization.</Typography>
        <Typography variant='h4'>Please ask your team lead or Simplemnt admin for an invitation.</Typography>
        <br /><br />
        <span style={{ fontSize: '1.6em', marginRight: '10px' }}>Your Account: <u>{session?.user?.email}</u></span><Button variant='contained' className={classes.buttonBlue} color='secondary' onClick={() => session.signout()}>Sign Out</Button>
        <br /><br />
        <Typography variant='h4'>If your team is new to Simplemnt, please contact us to get your account set up!</Typography>
        <br /><br />
        <Button variant='contained' size='large' color='secondary' fullWidth href='mailto:sales@simplemnt.com'>Contact Sales</Button>
      </CardContent>
    </Card>
  </Container>
}