import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Tooltip } from '@material-ui/core/'
import PropTypes from 'prop-types'
import { HelpOutline } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(1),
  },
  right: {
    position: 'absolute',
    right: '10px',
    top: '-5px',
  },
  help: {
    position: 'absolute',
    right: '-10px',
    top: '-10px',
  }
}))

export default function CardTitle(props) {
  const classes = useStyles()
  return (
    <div style={{ marginBottom: `${props.marginBottom || 20}px`, position: 'relative' }}>
      {props.title ? <Typography className={classes.margin} style={props.style} variant="h5">{props.title}</Typography> : ''}
      {props.subtitle ? <Typography className={classes.margin} style={props.style} variant="subtitle2">{props.subtitle}</Typography> : ''}
      {props.right ? <Typography className={classes.right} variant="h5">{props.right}</Typography> : ''}
      {props.help && <Tooltip className={classes.help} title={props.help}><HelpOutline fontSize='small' /></Tooltip>}
      {props.additional && props.additional}
    </div>
  )
}

CardTitle.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  right: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  style: PropTypes.object,
  marginBottom: PropTypes.number,
  additional: PropTypes.element,
  help: PropTypes.string
}