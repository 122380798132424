
import React, { Fragment } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { Typography, Tooltip } from '@material-ui/core'
import Router from 'next/router'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useSession } from '../../components/session/session'
import { useClient } from '../../components/session/clientSession'
import { findIncompleteDependency } from 'utils/progressbar'
import { routeToPageWithParams } from 'utils/utils'

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  sectionMobile: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  step: {
    '&:hover': {
      cursor: 'pointer'
    },
    '& svg': {
      color: 'var(--button-color) !important',
      '& text': {
        fill: 'var(--button-text-color)'
      }
    }
  }
}))

export default function ProgressBar({ noClient, disable, sections, nextSectionIndex }) {
  const clientSession = useClient()
  const session = useSession()
  let clientId
  if (noClient) {
    clientId = 'test'
  } else if (clientSession.client && session.user?.email) {
    clientId = clientSession?.clientRef?.id ?? 'test'
  }
  function go(s) {
    if (disable) return
    window.scrollTo(0, 0)
    if (session.external) Router.push('/onboarding/[clientid]/section/[sectionindex]', `/onboarding/${clientId}/section/${s}/`)
    else routeToPageWithParams([{ name: 'section', value: s }, { name: 'task', value: null }], Router)
  }
  const classes = useStyles()

  const stepperContent = sections.map((section, s) => {
    const data = Object.values(section.Data || section.Tasks || {})
    let showSection = data.length
    const sectionLocked = data.every(task => {
      if (task.Dependency && task.Dependency.ID) {
        if (task.Dependency.Type === 'Hide' && !task.Dependency.ConditionalPass) {
          showSection--
          return true
        }
        const dep = findIncompleteDependency(sections, task.Dependency.ID)
        if (dep.Name) return true
      }
      return false
    })
    const l = <StepLabel><Typography>{section.Name}</Typography><Typography>{section.DueDate ? dayjs(section.DueDate.toDate()).format(clientSession.client.DateFormat || 'MM/DD/YY') : ''}</Typography></StepLabel>
    if (showSection < 1) return <Fragment key={section.Name}></Fragment>
    return <Step key={section.Name} onClick={() => { if (!sectionLocked) go(s) }} disabled={false} className={(session.external || clientSession.forceClientView) ? classes.step : ''} completed={section.Data && Object.values(section.Data).every(e => e.Complete)}>
      {sectionLocked ? <Tooltip enterTouchDelay={50} title='Section Locked. Please complete previous steps first.'>{l}</Tooltip> : l}
    </Step>
  })

  return (<>
    <div className={classes.sectionDesktop}><Stepper alternativeLabel activeStep={nextSectionIndex} style={{ backgroundColor: 'transparent' }}>{stepperContent}</Stepper></div>
    <div className={classes.sectionMobile}><Stepper activeStep={nextSectionIndex} style={{ backgroundColor: 'transparent' }} orientation="vertical">{stepperContent}</Stepper></div>
  </>)
}

ProgressBar.propTypes = {
  nextSectionIndex: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
  noClient: PropTypes.bool,
  disable: PropTypes.bool
}