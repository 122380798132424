import React, { useState, useEffect, useContext, createContext } from 'react'
import { useRouter } from 'next/router'
import { useSession } from './session'
import PropTypes from 'prop-types'
import { firebase } from '@firebase/app'
import '@firebase/firestore'

const sessionContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useSession().
export function ClientProvider({ children }) {
  const client = useClientProvide()
  return <sessionContext.Provider value={client}>{children}</sessionContext.Provider>
}
ClientProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useClient = () => {
  return useContext(sessionContext)
}


// Provider hook that creates auth object and handles state
function useClientProvide() {
  const session = useSession()
  const [client, setClient] = useState(null)
  const [clientError, setClientError] = useState(null)
  const [clientRef, setClientRef] = useState(null)
  const [forceClientView, setForceClientView] = useState(false)
  const [preview, setPreview] = useState(false)
  const router = useRouter()
  const { clientid } = router.query

  const saveClient = async val => {
    session.setSaved(false)
    if (!val.Metadata) val.Metadata = {}
    val.Metadata.LastUpdated = firebase.firestore.FieldValue.serverTimestamp()
    await clientRef.set(val, { merge: true })
    session.setSaved(true)
  }

  useEffect(() => {
    if (!session.org) return
    if (!clientid) {
      setClient(null)
      setClientRef(null)
      setForceClientView(false)
      return
    }

    setClientRef(session.org.ref.collection('Clients').doc(clientid))
    return session.org.ref.collection('Clients').doc(clientid).onSnapshot(snap => {
      setClient(snap.data())
    }, error => {
      console.error('client session', error)
      setClientError(error)
    })
  }, [clientid, session.org, session.user, session.external])

  return {
    client,
    clientRef,
    setClientRef,
    setClient,
    saveClient,
    clientError,
    forceClientView,
    setForceClientView,
    preview,
    setPreview,
  }
}