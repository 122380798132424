import dayjs from 'dayjs'
import { firebase } from '@firebase/app'
import '@firebase/firestore'

export function calculateNotificationDates({ startDate, startDateOffset, endDateOffset, maxReminders, setReminderCadence, reminderCadence, reminderDaysBeforeDue, setReminderDaysBeforeDue }) {
  if (!startDateOffset) startDateOffset = 0
  if (!endDateOffset) endDateOffset = 5
  if (!maxReminders) maxReminders = 0
  if (!setReminderCadence) setReminderCadence = false
  if (!reminderCadence) reminderCadence = 1
  if (!reminderDaysBeforeDue) reminderDaysBeforeDue = 1
  if (!setReminderDaysBeforeDue) setReminderDaysBeforeDue = false
  let notificationDates = []
  const endDateForTask = startDate.add(endDateOffset, 'days')
  if (setReminderCadence) {
    for (let i = 0; i < maxReminders; i++) {
      const dueDate = endDateForTask.add((reminderCadence * i), 'days')
      notificationDates.push(dueDate.format('MM-DD-YYYY'))
    }
  }
  if (setReminderDaysBeforeDue) {
    const endReminderDate = startDate.add(endDateOffset, 'days').subtract(reminderDaysBeforeDue, 'days').format('MM-DD-YYYY')
    if (!notificationDates.includes(endReminderDate)) notificationDates.push(endReminderDate)
  }
  notificationDates = notificationDates.sort((a, b) => dayjs(a).valueOf() - dayjs(b).valueOf())
  if (notificationDates.length > maxReminders) notificationDates.pop()
  //console.log(startDate.format('MM-DD-YYYY'), startDateOffset, endDateOffset, maxReminders, reminderCadence, reminderDaysBeforeDue, notificationDates)
  return notificationDates
}

export function calculateTaskDateOffsets(sections, s, t, EstimatedDuration, StartDateOffset, EndDateOffset) {
  sections.forEach((section, s) => { if (section.Tasks) sections[s].Data = section.Tasks })
  let prevEndDate = sections[s]?.Data?.[t - 1]?.EndDateOffset || 0
  if (t === 0) {
    let prevSectionWithDate = Object.values(sections || {}).reverse().find(section => section.Data && section.Data[Object.values(section.Data || {}).length - 1] ? section.Data[Object.values(section.Data || {}).length - 1].EndDateOffset : false)
    prevEndDate = prevSectionWithDate ? Object.values(prevSectionWithDate.Data)[Object.values(prevSectionWithDate.Data).length - 1].EndDateOffset : 0
  }
  if (!StartDateOffset && StartDateOffset != 0) StartDateOffset = 0 + prevEndDate
  if (!EndDateOffset) EndDateOffset = Math.min(Math.floor(EstimatedDuration / 86400), 365) + StartDateOffset
  return [StartDateOffset, EndDateOffset]
}

export function getMaxDueDate(latestDay, item) {
  if (!item.DueDate) return latestDay || dayjs()
  const currentDueDate = dayjs(item.DueDate.toDate())
  //console.log(currentDueDate)
  if (!latestDay || currentDueDate.isAfter(latestDay)) return currentDueDate
  else return latestDay
}

export function compressTimeline(batch, selectedSectionIndex, templateData, clientSession, session) {

  if (!templateData?.NotificationCadence?.TimelineCompression) return
  let compressWhat = templateData?.NotificationCadence?.TimelineCompressionMode ?? 'All'

  // find incomplete task with closest start date
  let nextIncompleteOffset = 10000

  Object.values(templateData.Sections || {}).forEach((section, sectionIndex) => {
    if (compressWhat === 'Section' && sectionIndex != selectedSectionIndex) return
    Object.values(section.Data).forEach(task => {
      if (!task.Complete) nextIncompleteOffset = Math.min(nextIncompleteOffset, task.StartDateOffset)
    })
  })

  if (nextIncompleteOffset === 10000) return

  const datePointer = dayjs(templateData.Metrics.StartDate.toDate())
  if (Number.isNaN(datePointer)) return

  const compressBy = (datePointer.add(nextIncompleteOffset + 1, 'days')).diff(dayjs(), 'days')
  if (Number.isNaN(compressBy) || compressBy < 1) return

  Object.values(templateData.Sections).forEach((section, sectionIndex) => {
    if (compressWhat === 'Section' && sectionIndex != selectedSectionIndex) return
    let sectionDueDate = dayjs('1990-1-1')
    Object.values(section.Data).forEach((taskData, taskIndex) => {
      if (taskData.Complete) return

      taskData.StartDateOffset = taskData.StartDateOffset - compressBy
      taskData.EndDateOffset = taskData.EndDateOffset - compressBy

      const [startDateOffest, endDateOffset] = calculateTaskDateOffsets(Object.values(templateData.Sections), sectionIndex, taskIndex, taskData.EstimatedDuration, taskData.StartDateOffset, taskData.EndDateOffset)
      taskData.StartDateOffset = startDateOffest
      taskData.EndDateOffset = endDateOffset
      const dueDate = datePointer.add(taskData.EndDateOffset, 'days')
      taskData.DueDate = firebase.firestore.Timestamp.fromDate(dueDate.toDate())
      if (sectionDueDate.isBefore(dueDate)) sectionDueDate = dueDate

      const cadence = templateData.NotificationCadence
      templateData.Sections[sectionIndex].Data[taskIndex].NotificationDates = calculateNotificationDates({
        startDate: dayjs(),
        startDateOffset: taskData.StartDateOffset,
        endDateOffset: taskData.EndDateOffset,
        maxReminders: cadence.MaxReminders,
        reminderCadence: cadence.ReminderCadence,
        setReminderCadence: cadence.SetReminderCadence,
        reminderDaysBeforeDue: cadence.ReminderDaysBeforeDue,
        setReminderDaysBeforeDue: cadence.SetReminderDaysBeforeDue
      })

      batch.set(clientSession.clientRef.collection('Templates').doc(templateData.Sections[sectionIndex].Data[taskIndex].Ref), {
        DueDate: templateData.Sections[sectionIndex].Data[taskIndex].DueDate,
        NotificationDates: templateData.Sections[sectionIndex].Data[taskIndex].NotificationDates
      }, { merge: true })
    })
    if (sectionDueDate.isAfter(dayjs('1990-1-1'))) templateData.Sections[sectionIndex].DueDate = firebase.firestore.Timestamp.fromDate(sectionDueDate.toDate())
  })
  // Find max of all sections
  const TargetGoLive = firebase.firestore.Timestamp.fromDate(Object.values(templateData.Sections).reduce(getMaxDueDate, false).toDate())
  templateData.Variables.TargetGoLive = dayjs(TargetGoLive.toDate()).format('MMMM DD, YYYY')
  batch.set(clientSession.clientRef, {
    Metadata: templateData,
    TargetGoLive
  }, { merge: true })
  window.analytics.track('timeline compressed', {
    event_id: clientSession.clientRef.id,
    org_id: session.org.ref.id
  })

}