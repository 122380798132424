import { Drawer, List, ListItem, ListItemIcon, Divider, ListItemText, Tooltip, LinearProgress, Paper } from '@material-ui/core'
import { CheckCircle, HourglassFullTwoTone, ReportProblem, CalendarToday } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSession } from '../../components/session/session'
import { useClient } from '../../components/session/clientSession'
import { mapToArray, routeToPageWithParams } from '../../utils/utils'
import dayjs from 'dayjs'
import Skeleton from '@material-ui/lab/Skeleton'
import { findIncompleteDependency } from '../../utils/progressbar'
import Router from 'next/router'
import { replaceVariables } from '../../utils/variables'
import { useClientTask } from '../session/clientTaskSession'
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { localize } from 'components/localization/strings'

const drawerWidth = 265
const logoHeight = '44px'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  logo: {
    height: logoHeight,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    margin: '10px',
  },
  logoBg: {
    backgroundColor: 'var(--logo-background) !important',
    borderRadius: '0px',
    border: '0px'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiPaper-root': {
      backgroundColor: 'var(--nav-background)',
      border: '0px'
    }
  },
  sidebarLink: {
    color: 'var(--nav-text)',
    '&:hover': {
      backgroundColor: 'var(--nav-highlight)'
    },
    '&.Mui-selected': {
      backgroundColor: 'var(--nav-highlight)',
      filter: 'grayscale(50%)',
      '&:hover': {
        filter: 'none',
        backgroundColor: 'var(--nav-highlight)'
      },
    }
  },
  indentedLink: {
    marginLeft: '20px'
  },
  ListItemIcon: {
    minWidth: '0px',
    color: 'var(--button-color)',
  },
  sectionHeader: {
    fontWeight: 'bold'
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  progressBar: {
    margin: '5px 20px 5px 20px',
    '& p': {
      color: 'var(--nav-text)',
      margin: '0px 0px 5px 0px'
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'var(--nav-text)'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

export default function ExternalSidebar({ orgData, client }) {
  const classes = useStyles()
  const clientSession = useClient()
  const clientTaskSession = useClientTask()
  const session = useSession()

  let links = []

  for (let i = 0; i < 10; i++) {
    links.push(
      <ListItem dense={true} key={Math.random()}>
        <Skeleton height='40px' width={`${drawerWidth}px`}></Skeleton>
      </ListItem>
    )
  }
  if ((clientSession.client && session.user?.email) || client) {
    const clientId = clientSession?.clientRef?.id ?? ''
    const clientData = clientSession?.client ?? client
    const templateData = clientData.Metadata

    if (!clientData.ClientTeam) clientData.ClientTeam = {}
    links = []

    const sections = mapToArray(templateData.Sections)

    // Progress
    const totalTasks = sections.reduce((accumulator, currentValue) => accumulator + mapToArray(currentValue.Data).filter(e => !(e.Dependency && e.Dependency.Type === 'Hide' && !e.Dependency.ConditionalPass)).length, 0)
    const tasksCompleted = sections.reduce((accumulator, currentValue) => accumulator + mapToArray(currentValue.Data).filter(e => !(e.Dependency && e.Dependency.Type === 'Hide' && !e.Dependency.ConditionalPass)).filter(e => e.Complete).length, 0)
    links.push(<div className={classes.progressBar} key='progressbar'>
      <p>{`${Math.round(100 * tasksCompleted / totalTasks)}% ${localize('completed', clientData.Language)}`}</p>
      <LinearProgress variant="determinate" value={tasksCompleted * 100 / totalTasks} />
    </div>)

    const permissions = clientData.ClientTeam[session.user.email] || clientData.ClientTeam['public'] || {}
    if (!permissions.NoView) permissions.NoView = []

    links.push(<Fragment key='toplink'><ListItem dense={false} button className={classes.sidebarLink} style={{ textAlign: 'center' }} onClick={() => {
      if (!client) {
        window.scrollTo(0, 0)
        if (session.external) Router.push('/onboarding/[clientid]', `/onboarding/${clientId}`)
        else routeToPageWithParams([{ name: 'section', value: null }, { name: 'task', value: null }], Router)
      }
    }}>
      <ListItemText primaryTypographyProps={{ className: classes.sectionHeader }} primary={localize('Timeline and Status', clientData.Language)} />
    </ListItem><Divider /></Fragment>)
    if (templateData.ShowGoals) {
      links.push(<Fragment key='goals'><ListItem dense={false} button className={classes.sidebarLink} style={{ textAlign: 'center' }} onClick={() => {
        if (!client) {
          window.scrollTo(0, 0)
          if (session.external) Router.push('/onboarding/[clientid]/goals', `/onboarding/${clientId}/goals`)
          else routeToPageWithParams([{ name: 'section', value: 'goals' }, { name: 'task', value: null }], Router)
        }
      }}>
        <ListItemText primaryTypographyProps={{ className: classes.sectionHeader }} primary={localize('Goals and Outcomes', clientData.Language)} />
      </ListItem><Divider /></Fragment>)
    }
    sections.forEach((section, s) => {
      section = replaceVariables(section, templateData.Variables || {})
      let numlinks = 0, numDisabled = 0, sectionLinks = [], numComplete = 0
      mapToArray(section.Data).forEach((task, t) => {
        if (permissions.NoView[task.Ref] === true) return // Don't render tasks this user should not see
        if (task.TaskType === 'Internal Process' && permissions.NoView[task.Ref] != false && (session.external || clientSession.forceClientView)) return // Don't render internal tasks unless there is a view override
        if (task.Hidden) return // Skip Hidden Tasks
        let disable = false
        let icon = <></>
        let tooltipTitle = ''
        if (task.Complete) {
          icon = <CheckCircle fontSize="small" />
          tooltipTitle = 'Task Complete'
        } else if (task.LastClientUpdated) {
          if (task.RequireInternalConfirmation && task.ClientConfirmation) {
            icon = <HourglassFullTwoTone fontSize="small" />
            tooltipTitle = 'Waiting for Confirmation'
          } else {
            icon = <ReportProblem fontSize="small" />
            tooltipTitle = 'Task Not Complete'
          }
        } else if (task.Dependency && task.Dependency.ID) {
          if (task.Dependency.Type === 'Hide' && !task.Dependency.ConditionalPass && (!clientSession.preview || clientSession.forceClientView || session.external)) return
          const dep = findIncompleteDependency(templateData.Sections, task.Dependency.ID)
          if (dep.Name) {
            disable = clientSession.forceClientView || !(clientData.TeamArray && clientData.TeamArray.includes(session.user.email))
            if (disable) numDisabled++
            if (dep.TaskType === 'Internal Process') {
              tooltipTitle = 'Configuration in process. Please wait.'
            } else {
              tooltipTitle = `Please finish the ${dep.Name} task first`
              if (task.Dependency.Type === 'Hide' && clientSession.preview) tooltipTitle = '(Hidden Task) - ' + tooltipTitle
            }
          }
        } else if (task.DueDate) {
          if (dayjs(task.DueDate.toDate()).isBefore(dayjs())) {
            icon = <CalendarToday fontSize="small" />
            tooltipTitle = 'Task Overdue!'
          }
        }
        numlinks++
        if (task.Complete) numComplete++
        sectionLinks.push(<Tooltip title={tooltipTitle} key={`section-${s}-task-${t}`} id={`section-${s}-task-${t}`}><div>
          <ListItem disabled={(session.external || clientSession.forceClientView) && disable} dense={true} button className={classes.sidebarLink} selected={!client && clientTaskSession?.taskRef?.id === task.Ref} onClick={() => {
            if (!client) {
              window.scrollTo(0, 0)
              if (session.external) Router.push('/onboarding/[clientid]/task/[sectionindex]/[taskid]', `/onboarding/${clientId}/task/${s}/${task.Ref}`)
              else routeToPageWithParams([{ name: 'section', value: s }, { name: 'task', value: task.Ref }], Router)
            }
          }}>
            <ListItemText primary={task.Name} className={classes.indentedLink} />
            <ListItemIcon className={classes.ListItemIcon}>{icon}</ListItemIcon>
          </ListItem>
        </div></Tooltip>)
      })
      if (numlinks > 0) {
        const isComplete = numComplete === numlinks
        const disabled = numDisabled === numlinks
        const link =
          <ListItem disabled={disabled} dense={true} button key={`section-${s}`} className={classes.sidebarLink} onClick={() => {
            if (!client) {
              window.scrollTo(0, 0)
              if (session.external) Router.push('/onboarding/[clientid]/section/[sectionindex]', `/onboarding/${clientId}/section/${s}/`)
              else routeToPageWithParams([{ name: 'section', value: s }, { name: 'task', value: null }], Router)
            }
          }}>
            <ListItemText primaryTypographyProps={{ className: classes.sectionHeader }} primary={section.Name} />
            {isComplete && <ListItemIcon className={classes.ListItemIcon}><CheckCircle /></ListItemIcon>}
          </ListItem>
        sectionLinks.unshift(link)
        links = links.concat(sectionLinks)
      }
    })
    /*links.push(<div key='bottomlink'><Divider /><ListItem dense={true} button className={classes.sidebarLink} style={{ textAlign: 'center' }} onClick={() => {
      Router.push('/onboarding/[clientid]', `/onboarding/${clientId}`)
    }}>
      <ListItemText primaryTypographyProps={{ className: classes.sectionHeader }} primary='' secondary='Powered by Simplemnt' />
    </ListItem></div>)*/
  }

  if (orgData) session.org.data = orgData
  const content = <>
    {session?.org?.data?.Logo ?
      <Paper elevation={2} className={classes.logoBg}>
        <div className={`${classes.logo} ${classes.sectionDesktop}`} style={{ backgroundImage: `url(${session.org.data.Logo})` }} />
      </Paper> : <Skeleton height={logoHeight} width={`${drawerWidth}px`} />}
    <List>
      {links}
    </List>
  </>

  return (<>
    <div className={classes.sectionDesktop}><Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper, }} anchor="left" PaperProps={{
      style: {
        position: !session.external ? 'absolute' : 'fixed',
        zIndex: !session.external ? 0 : 1200,
      }
    }}>{content}</Drawer></div>
    <div className={classes.sectionMobile}><Drawer className={classes.drawer} open={session.mobileSidebarOpen} classes={{ paper: classes.drawerPaper, }} onClose={() => session.setMobileSidebarOpen(false)} anchor="left">{content}</Drawer></div>
  </>)

}

ExternalSidebar.propTypes = {
  client: PropTypes.object,
  orgData: PropTypes.object
}