export function localize(str, language) {
  const strings = {
    'Your Next Step': {
      'fr-CA': 'Votre prochaine étape'
    },
    'Onboarding Complete': {
      'fr-CA': 'Intégration terminée'
    },
    'Start': {
      'fr-CA': 'Démarrer la tâche'
    },
    'Goals and Outcomes': {
      'fr-CA': 'Objectifs et Résultats'
    },
    'Comments': {
      'fr-CA': 'Commentaires'
    },
    'Leave a message': {
      'fr-CA': 'Laisser un message'
    },
    'Attach Files': {
      'fr-CA': 'Joindre des Fichiers'
    },
    'Download': {
      'fr-CA': 'Télécharger'
    },
    'Download Table as CSV': {
      'fr-CA': 'Télécharger le tableau au format CSV'
    },
    'Upload CSV to Fill Table': {
      'fr-CA': 'Télécharger CSV pour remplir le tableau'
    },
    'Add': {
      'fr-CA': 'Ajouter'
    },
    'Row': {
      'fr-CA': 'Ligne'
    },
    'Due in': {
      'fr-CA': 'Dû en'
    },
    'Day': {
      'fr-CA': 'Journée'
    },
    'Contact': {
      'fr-CA': 'Contactez'
    },
    'Support': {
      'fr-CA': 'Obtenir de l\'aide'
    },
    'Timeline and Status': {
      'fr-CA': 'Chronologie et Statut'
    },
    'completed': {
      'fr-CA': 'd\'achèvement'
    },
    'Complete!': {
      'fr-CA': 'Compléter!'
    },
    'Provisioning in progress. Please check back soon!': {
      'fr-CA': 'Approvisionnement en cours. Veuillez vérifier bientôt!'
    },
    'Pending Review': {
      'fr-CA': 'En attendant l\'examen'
    },
    'Save and Continue': {
      'fr-CA': 'Sauvegarder et continuer'
    },
    'Save': {
      'fr-CA': 'Sauvegarder'
    },
    'Next': {
      'fr-CA': 'Allez ensuite'
    },
    'Save and Add Another': {
      'fr-CA': 'Enregistrer et ajouter un autre'
    },
    'Add Another': {
      'fr-CA': 'Ajouter un autre'
    },

  }
  if (!language) language = 'en-US'
  return strings?.[str]?.[language] ?? str
}