
import { mapToArray, arrayToMap } from './utils'
import ProgressBar from '../components/progressbar/progressbar'
import { checkConditionalRules } from './rules'
import { firebase } from '@firebase/app'
import '@firebase/firestore'

export function progressBar({ sections, disable = false, skip = 'Internal Process', all }) {

  const checkComplete = (e, total) => e.Complete || e.TaskType === skip || e.Hidden || (!total && e.Dependency?.Type === 'Hide' && !e.Dependency?.ConditionalPass)

  let nextSectionIndex = -1
  let nextTaskIndex = -1
  let nextTask, nextSection
  let isTotallyComplete = sections.every(section => mapToArray(section.Data).every(e => checkComplete(e, true)))

  function getNextTask(skipUnpublished) {
    sections.forEach((section, sectionIndex) => {
      const data = mapToArray(section.Data)
      const isComplete = data.every(checkComplete)
      if (!isComplete && nextSectionIndex === -1) {
        nextSectionIndex = sectionIndex
        nextSection = sections[sectionIndex]
        if (all) {
          nextTaskIndex = data.findIndex(e => {
            if (e.Hidden) return false
            if (!e.Complete) {
              if (e?.Dependency?.ID) {
                if (e.Dependency.Type === 'Hide' && !e.Dependency.ConditionalPass) return false
                return !findIncompleteDependency(sections, e.Dependency.ID).Name
              }
              return true
            }
            return false
          })
        } else {
          nextTaskIndex = data.findIndex(e => {
            if (e.Hidden) return false
            if (e.ClientConfirmation != true && e.TaskType != skip) {
              if (e.TaskType == 'Confirmation from Client' && !e.Published && skipUnpublished) {
                return false
              }
              if (e?.Dependency?.ID) {
                if (e.Dependency.Type === 'Hide' && !e.Dependency.ConditionalPass) return false
                return !findIncompleteDependency(sections, e.Dependency.ID).Name
              }
              return true
            }
            return false
          })
        }
        if (nextTaskIndex < 0) { // Could not find any tasks that are completable in this section
          nextSectionIndex = -1
          nextTask = null
        } else {
          nextTask = nextSection.Data[nextTaskIndex]
        }
      }
    })
  }
  getNextTask(true)
  if (!nextTask) getNextTask(false)

  function getBar() {
    return <ProgressBar nextSectionIndex={nextSectionIndex} sections={sections} disable={disable} />
  }

  return [getBar, nextTask, nextSection, nextSectionIndex, nextTaskIndex, isTotallyComplete]
}

export function findIncompleteDependency(sections, id) {
  return Object.values(sections).reduce((found, section) => {
    const dep = Object.values(section.Data || {}).filter(task => task.TaskID === id && !task.Complete)
    if (dep.length > 0) return dep[0]
    else return found
  }, {})
}

export function updateDependencyConditionals(sections, id, components) {
  return arrayToMap(Object.values(sections || {}).map(section => {
    section.Data = Object.values(section.Data || {}).map(task => {
      if (task.Dependency && task.Dependency.ID === id && task.Dependency.Type === 'Hide') {
        if (task.Dependency.ConditionalRules && task.Dependency.ConditionalRules.length > 0) {
          task.Dependency.ConditionalPass = task.Dependency.ConditionalRules.every(rule => {
            const componentIndex = (rule.ComponentID ? Object.values(components || {}).findIndex(e => e.ComponentID === rule.ComponentID) : rule.Component)

            // If component is hidden, then this is always false
            const conditionals = components.reduce((newShow, elm, elmIndex) => checkConditionalRules(elm, elmIndex, 0, components, newShow), []) // the zero is hardcode to the first version, lists not supported for conditionals
            if (!conditionals[componentIndex]) return false

            const value = components?.[componentIndex]?.Value?.[0] ?? []  // the zero is hardcode to the first version, lists not supported for conditionals
            if (!rule.Value) rule.Value = []
            if (rule.ValueType === 'any' && value.some(e => !!e)) return true
            else if (rule.ValueType === 'blank' && value.every(e => !e)) return true
            else if ((!rule.ValueType || rule.ValueType === 'equal') && value.some(v => rule.Value.some(r => r.trim() === (v || '').trim()))) return true
            return false
          })
        } else {
          task.Dependency.ConditionalPass = true
        }
      }
      return task
    })
    return section
  }))
}

export function calculateNextTask(session, clientSession, Metadata, batch) {
  // Calculate next task
  const [, nextTask, , nextSectionIndex, , isTotallyComplete] = progressBar({ sections: Object.values(Metadata.Sections), all: true, skip: false })
  if (isTotallyComplete && !Metadata.Metrics.CompletionTime) {
    batch.set(clientSession.clientRef, {
      Metadata: {
        Metrics: {
          CompletionTime: firebase.firestore.FieldValue.serverTimestamp()
        }
      }
    }, { merge: true })

    window.analytics.track('onboarding complete', {
      event_id: clientSession.clientRef.id,
      org_id: session.org.ref.id
    })
  }
  const NextStep = {
    Name: nextTask?.Name || null,
    Type: nextTask?.TaskType || null,
    DueDate: nextTask?.DueDate || null,
    Responsible: 'external',
    TotallyComplete: isTotallyComplete,
    SectionIndex: nextSectionIndex,
    TaskRef: nextTask?.Ref || null,
    TaskID: nextTask?.TaskID || null
  }
  if (nextTask?.TaskType === 'Internal Process') NextStep.Responsible = 'internal'
  if (nextTask?.TaskType === 'Confirmation from Client' && !nextTask.Published) NextStep.Responsible = 'internal'
  if (nextTask?.TaskType === 'Input from Client' && nextTask.RequireInternalConfirmation && nextTask.ClientConfirmation && !nextTask.Complete) NextStep.Responsible = 'internal'
  batch.set(clientSession.clientRef, { NextStep }, { merge: true })
}