import React, { useState, useEffect, useContext, createContext } from 'react'
import { useRouter } from 'next/router'
import Router from 'next/router'
import { useClient } from './clientSession'
import PropTypes from 'prop-types'
import { mapToArray } from '../../utils/utils'
import { useSession } from './session'
import { firebase } from '@firebase/app'
import '@firebase/storage'
import '@firebase/firestore'
import { calculateNextTask, progressBar, updateDependencyConditionals } from '../../utils/progressbar'
import { compressTimeline } from 'utils/dates'
// eslint-disable-next-line no-undef
const rrweb = require('rrweb')

const sessionContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useSession().
export function ClientTaskProvider({ children }) {
  const task = useTaskProvide()
  return <sessionContext.Provider value={task}>{children}</sessionContext.Provider>
}
ClientTaskProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useClientTask = () => {
  return useContext(sessionContext)
}
// Provider hook that creates auth object and handles state
function useTaskProvide() {
  const session = useSession()
  const clientSession = useClient()
  const [task, setTask] = useState(null)
  const [taskRef, setTaskRef] = useState(null)
  const [taskId, setTaskId] = useState(false)
  const [allowModify, setAllowModify] = useState(false)
  const [taskIndex, setTaskIndex] = useState(null)
  const [sectionIndex, setSectionIndex] = useState(null)

  const saveTask = ({ ClientConfirmation, Complete, Continue, notBlank, isValid, Published }) => {
    console.log(ClientConfirmation, Complete, Continue, notBlank, isValid, Published)

    if (isValid) {
      // If there are items at -1 index, move them to the end of the value list
      const numValues = Math.max(...task.Components.map(elm => Object.values(elm.Value || {}).length - 1 || 0))

      task.Components.forEach((elm, elmIndex) => {
        Object.keys(elm.Value || {}).forEach(valIndex => {
          if (valIndex == -1) {
            if (task.Components[elmIndex].Value[valIndex].some(e => e)) // make sure there is at least some data in this component
              task.Components[elmIndex].Value[numValues] = task.Components[elmIndex].Value[valIndex]
            delete task.Components[elmIndex].Value[valIndex]
          }
        })
      })

      // make sure there are no null or undefined values
      task.Components.forEach((elm, elmIndex) => {
        Object.keys(elm.Value || {}).forEach(valIndex => {
          task.Components[elmIndex].Value[valIndex] = Object.values(task.Components[elmIndex].Value[valIndex] || {}).filter(e => e) // make sure there is at least some data in this component
        })
      })

      task.ClientConfirmation = task.ClientConfirmation || ClientConfirmation || false
      if (session.external && !(task.RequireInternalConfirmation && task.TaskType === 'Input from Client')) task.Complete = task.Complete || Complete || false
      else if (!session.external) task.Complete = Complete || false
      task.LastUpdated = firebase.firestore.FieldValue.serverTimestamp()
      task.LastUpdatedBy = session.user.email
      if (task.TaskType === 'Confirmation from Client' && typeof Published === 'boolean') {
        task.Published = !!Published
        if (Published) task.PublishedTime = task.LastUpdated
      }
      const Metadata = clientSession.client.Metadata
      Metadata.LastUpdated = task.LastUpdated
      const batch = firebase.firestore().batch()

      // If there are conditional dependencies on this task, calculate them and update
      Metadata.Sections = updateDependencyConditionals(Metadata.Sections, Metadata.Sections[sectionIndex].Data[taskIndex].TaskID, task.Components)

      if (!session.external) {
        Metadata.Sections[sectionIndex].Data[taskIndex].Complete = task.Complete || false
        if (task.TaskType === 'Internal Process') {
          Metadata.Sections[sectionIndex].Data[taskIndex].Complete = notBlank
        } else if (task.Published) {
          Metadata.Sections[sectionIndex].Data[taskIndex].Published = true
          Metadata.Sections[sectionIndex].Data[taskIndex].PublishedTime = new Date()//task.PublishedTime
        }
        batch.set(clientSession.clientRef, { Metadata }, { merge: true })
      } else if (task.ClientConfirmation) {
        if (!notBlank) {
          task.ClientConfirmation = false
          task.Complete = false
        }
        task.LastClientUpdated = task.LastUpdated
        Metadata.Sections[sectionIndex].Data[taskIndex].ClientConfirmation = task.ClientConfirmation || false
        Metadata.Sections[sectionIndex].Data[taskIndex].Complete = task.Complete || false
        Metadata.Sections[sectionIndex].Data[taskIndex].LastClientUpdated = new Date()//task.LastClientUpdated
        batch.set(clientSession.clientRef, { Metadata }, { merge: true })
      }

      calculateNextTask(session, clientSession, Metadata, batch)

      if (task.Complete) compressTimeline(batch, sectionIndex, Metadata, clientSession, session)
      batch.set(taskRef, task)
      batch.set(taskRef.collection('Versions').doc(), task)
      session.setSaved(false)
      console.log(task)
      session.snackbar.setSnackbar({
        severity: 'info',
        message: 'Saving... Please Do Not Leave'
      })
      batch.commit().then(() => {
        session.snackbar.setSnackbar({
          severity: 'success',
          message: 'Task Saved'
        })
      }).catch(error => {
        console.error(error)
        session.snackbar.setSnackbar({
          severity: 'error',
          message: 'There was an error saving. Please try again later.'
        })
      })
    } else {
      session.snackbar.setSnackbar({
        severity: 'error',
        message: 'Errors Found. Please fix before saving'
      })
    }
    session.setSaved(true)

    // On Save and Continue, go to the next task
    if (isValid && Continue && session.external) {
      const [, nextTask, , nextSectionIndex] = progressBar({ sections: Object.values(clientSession.client.Metadata.Sections) })
      window.scrollTo(0, 0)
      if (nextTask) Router.push('/onboarding/[clientid]/task/[sectionindex]/[taskid]', `/onboarding/${clientSession.clientRef.id}/task/${nextSectionIndex}/${nextTask.Ref}`)
      else Router.push('/onboarding/[clientid]', `/onboarding/${clientSession.clientRef.id}`)
    }

    // Clean up orphan files in storage
    getSubfolder(`${session.org.ref.id}/${clientSession.clientRef.id}/${sectionIndex}/${taskRef.id}/`).then(filesInStorage => {
      //Delete orphan files in storage
      const filesWithRefs = task.Components.map(component => {
        if (component.Type === 'fileUpload')
          return mapToArray(component.Value).map(e => e.map(e => {
            if (e?.FileRef) return e.FileRef
            return e
          })).flat()
      }).flat().filter(e => e)
      const orphanFiles = filesInStorage.filter(file => filesWithRefs.indexOf(file) < 0)
      console.log('Orphan Files:', orphanFiles)
      orphanFiles.forEach(async fullpath => {
        const fileRef = firebase.storage().ref(fullpath)
        try { await fileRef.delete() } catch (e) { console.log('File not found') }
      })
    })

    return isValid

  }

  const router = useRouter()

  let sectionindex, taskid
  if (router?.query?.section) {
    sectionindex = router.query.section
    taskid = router.query.task
  } else {
    sectionindex = router.query.sectionindex
    taskid = router.query.taskid
  }

  useEffect(() => {
    if (!(session.external && sectionindex && taskid && clientSession.clientRef && session.user.email)) return
    const timeStart = performance.now()
    let events = []
    let eventLog = clientSession.clientRef.collection('Events').doc()
    rrweb.record({
      emit(event) { events.push(event) }
    })
    const save = () => {
      const timeEnd = performance.now()
      const jsonEvents = JSON.stringify(events)
      const pakoWorker = new Worker(window.URL.createObjectURL(new Blob(['self.onmessage = function(e) {importScripts(\'https://cdnjs.cloudflare.com/ajax/libs/pako/2.0.2/pako_deflate.min.js\');self.postMessage(pako.deflateRaw(e.data))}'])))
      pakoWorker.onmessage = (compressed) => {
        console.timeEnd('compress rrweb')
        try {
          eventLog.set({
            SessionTime: timeEnd - timeStart,
            rrwebEvents: firebase.firestore.Blob.fromUint8Array(compressed.data)
          }, { merge: true })
        } catch (e) {
          console.error('video too long')
          eventLog.set({
            SessionTime: timeEnd - timeStart,
            rrwebEvents: null
          }, { merge: true })
        }

        // Clear Events if video size is over 0.5 MB
        if (compressed.data.byteLength > 5e+6) {
          events = []
          eventLog = clientSession.clientRef.collection('Events').doc()
          eventLog.set({
            EventTime: firebase.firestore.FieldValue.serverTimestamp(),
            URL: window.location.pathname,
            UserID: session.user.email,
            Encoding: 'pako'
          }).catch(error => console.error('rrweb', error))
        }
      }
      console.time('compress rrweb')
      pakoWorker.postMessage(jsonEvents)
    }
    eventLog.set({
      EventTime: firebase.firestore.FieldValue.serverTimestamp(),
      URL: window.location.pathname,
      UserID: session.user.email,
      Encoding: 'pako'
    }).catch(error => console.error('rrweb', error))

    const recordingInterval = setInterval(save, 30000)
    return () => {
      save()
      clearInterval(recordingInterval)
    }
  }, [clientSession.clientRef, sectionindex, session.external, session?.user?.email, taskid])

  // When routing to a new page, clear out the current task to prevent data mixing
  if (sectionindex && taskId != taskid && task != null) {
    setSectionIndex(null)
    setTask(null)
    setTaskRef(null)
  }

  useEffect(() => {
    if (!taskid || !sectionindex) return
    if (!clientSession.client || !clientSession.clientRef || typeof sectionindex === 'undefined' || typeof taskid === 'undefined') {
      if (task != null) {
        setTask(null)
        setTaskRef(null)
      }
      return
    }
    const templateData = clientSession.client.Metadata
    if (!templateData.Sections) return // if there are no sections, there is something wrong with this onboarding hub. Exit quickly
    const section = templateData.Sections[sectionindex]
    if (!section) {
      if (session.external) Router.push('/onboarding/[clientid]', `/onboarding/${clientSession.clientRef.id}`) // section does not exist
      return
    }
    const taskIndex = mapToArray(section.Data).findIndex(e => e.Ref === taskid)
    if (taskIndex < 0) {
      if (session.external) Router.push('/onboarding/[clientid]', `/onboarding/${clientSession.clientRef.id}`) // task does not exist
      return
    }
    const task = section.Data[taskIndex]
    if (!task) {
      if (session.external) Router.push('/onboarding/[clientid]', `/onboarding/${clientSession.clientRef.id}`) // task does not exist
      return
    }
    const permissions = clientSession.client.ClientTeam?.[localStorage.getItem('userEmail')] || clientSession.client.ClientTeam?.['public'] || {}
    if (!permissions.NoEdit) permissions.NoEdit = []
    if (!permissions.NoView) permissions.NoView = []
    if (permissions.NoView[task.Ref] === true) {
      if (session.external) Router.push('/onboarding/[clientid]', `/onboarding/${clientSession.clientRef.id}`) // no view permissions
      return
    }
    let readonly = permissions.NoEdit[task.Ref] === true
    if (task.TaskType === 'Confirmation from Client') {
      if (permissions.NoEdit[task.Ref] === false) readonly = false
      else readonly = true
    } else if (task.TaskType === 'Internal Process') {
      readonly = true
    }
    setAllowModify(!readonly)
    const ref = clientSession.clientRef.collection('Templates').doc(task.Ref)
    setTaskRef(ref)
    return ref.onSnapshot(snap => {
      //if (snap.metadata.hasPendingWrites) return
      setTaskIndex(taskIndex)
      setSectionIndex(sectionindex)
      setTaskId(taskid)
      setTask(snap.data())
    }, error => console.error('client task', error))
  }, [clientSession.client, clientSession.clientRef, sectionindex, session.external, taskid])

  return {
    sectionIndex,
    taskIndex,
    task,
    taskRef,
    setTask,
    saveTask,
    allowModify,
  }
}

export async function getSubfolder(path) {
  const i = await firebase.storage().ref(path).listAll()
  const subfolders = (await Promise.all(i.prefixes.map(async prefix => await getSubfolder(prefix.fullPath)))).flat()
  const filteredItems = i.items.filter(item => item.fullPath).map(item => item.fullPath)
  if (subfolders.length > 0)
    return [...subfolders, ...filteredItems]
  else
    return filteredItems
}