import React from 'react'
import { useSession } from '../session/session.js'
import InternalTopAppBar from './internalTopAppBar'
import ExternalTopAppBar from './externalTopAppBar'
import ExternalSidebar from '../sidebar/externalSidebar'
import { AppBar, Toolbar } from '@material-ui/core'

export default function TopAppBar() {
  const session = useSession()
  if (typeof location != 'undefined' && (location.pathname === '/' || location.pathname === '' || location.pathname === '/onboarding/simplemnt')) return <></>
  if (session.external) return <><ExternalTopAppBar /><ExternalSidebar /></>
  if (!session.user) return <AppBar position="static" style={{ backgroundColor: 'transparent' }} elevation={0}><Toolbar /></AppBar> // Show a transparent app bar to prevent page reflow
  return <>
    <InternalTopAppBar />
    <div style={{ height: '128px' }} />
  </>
}