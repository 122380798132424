import React from 'react'
import App from 'next/app'
import Head from 'next/head'
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { pink } from '@material-ui/core/colors'
import { SessionProvider } from '../components/session/session'
import { ClientProvider } from '../components/session/clientSession'
import { CssBaseline } from '@material-ui/core'
import TopAppBar from '../components/appbar/topAppBar'
import MainContent from '../components/maincontent'
import './style.scss'
import { ClientTaskProvider } from '../components/session/clientTaskSession'

const theme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      light: '#5f6b72',
      main: '#37474f',
      dark: '#263137',
      contrastText: '#fff',
    },
    secondary: pink,
    background: {
      default: '#303030'
    }
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: 'var(--font, sans-serif)'
  },
}))

/*export function reportWebVitals(metric) {
  // These metrics can be sent to any analytics service
  console.log(metric)
}*/

// eslint-disable-next-line no-undef
const title = `${process.env.NODE_ENV === 'development' ? 'DEV - ' : ''}Simplemnt`
const fsorg = 'Q24X0'
export default class Root extends App {
  render() {
    const { Component, pageProps } = this.props
    if (typeof window !== 'undefined') {

      if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach
      }

      //Polyfill for slice 
      //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/TypedArray/slice
      if (window.Uint8Array && !Uint8Array.prototype.slice) {
        Object.defineProperty(Uint8Array.prototype, 'slice', {
          value: function (begin, end) {
            return new Uint8Array(Array.prototype.slice.call(this, begin, end))
          }
        })
      }


      //Polyfill for codePointAt
      //http://xahlee.info/js/js_String.prototype.codePointAt.html
      if (!String.prototype.codePointAt) {

        String.prototype.codePointAt = function fixedCharCodeAt(idx) {
          // 2017-02-24 from https://developer.mozilla.org/en-US/docs/JavaScript/Reference/Global_Objects/String/charCodeAt
          // 2017-02-24 xah lee, made to be compatible with es2015 codePointAt
          // ex. fixedCharCodeAt ('\uD800\uDC00', 0); // 65536
          // ex. fixedCharCodeAt ('\uD800\uDC00', 1); // 65536
          idx = idx || 0
          var code = this.charCodeAt(idx)
          var hi, low
          if (0xD800 <= code && code <= 0xDBFF) { // High surrogate (could change last hex to 0xDB7F to treat high private surrogates as single characters)
            hi = code
            low = this.charCodeAt(idx + 1)
            if (isNaN(low)) {
              throw 'High surrogate not followed by low surrogate in fixedCharCodeAt()'
            }
            return ((hi - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000
          }
          if (0xDC00 <= code && code <= 0xDFFF) { // Low surrogate
            // We return false to allow loops to skip this iteration since should have already handled high surrogate above in the previous iteration
            return false
            /*hi = this.charCodeAt(idx-1)
              low = code
              return ((hi - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000;*/
          }
          return code
        }
      }

      //Polyfill for Includes
      //https://stackoverflow.com/questions/31221341/ie-does-not-support-includes-method
      if (!Array.prototype.includes) {
        Object.defineProperty(Array.prototype, 'includes', {
          enumerable: false,
          value: function (obj) {
            var newArr = this.filter(function (el) {
              return el == obj
            })
            return newArr.length > 0
          }
        })
      }
    }

    return (
      <>
        <Head>
          <title>{title}</title>
          <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.snow.css' />
          <script dangerouslySetInnerHTML={{ __html: '!function () { var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else { analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"]; analytics.factory = function (t) { return function () { var e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics } }; for (var t = 0; t < analytics.methods.length; t++) { var e = analytics.methods[t]; analytics[e] = analytics.factory(e) } analytics.load = function (t, e) { var n = document.createElement("script"); n.type = "text/javascript"; n.async = !0; n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(n, a); analytics._loadOptions = e }; analytics.SNIPPET_VERSION = "4.1.0"; } }();' }} />
          <script dangerouslySetInnerHTML={{ __html: `window['_fs_debug']=false;window['_fs_host']='fullstory.com';window['_fs_script']='edge.fullstory.com/s/fs.js';window['_fs_org']='${fsorg}';window['_fs_namespace']='FS';(function(m,n,e,t,l,o,g,y){if(e in m){if(m.console&&m.console.log){m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].')}return}g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s)};g.q=[];o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};g.anonymize=function(){g.identify(!!0)};g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};g.log=function(a,b){g("log",[a,b])};g.consent=function(a){g("consent",!arguments.length||a)};g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};g.clearUserCookie=function(){};g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};g._v="1.2.0"})(window,document,window['_fs_namespace'],'script','user');` }} />
        </Head>
        <SessionProvider>
          <ClientProvider>
            <ClientTaskProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <TopAppBar />
                <MainContent Component={Component} {...pageProps} />
              </ThemeProvider>
            </ClientTaskProvider>
          </ClientProvider>
        </SessionProvider>
      </>
    )
  }
}